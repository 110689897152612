const suburbs = [
  {
    "postcode": 4001,
    "name": "Brisbane",
    "value": "Brisbane"
  },
  {
    "postcode": 4004,
    "name": "Spring Hill",
    "value": "Spring Hill"
  },
  {
    "postcode": 4005,
    "name": "New Farm",
    "value": "New Farm"
  },
  {
    "postcode": 4005,
    "name": "Teneriffe",
    "value": "Teneriffe"
  },
  {
    "postcode": 4006,
    "name": "Bowen Hills",
    "value": "Bowen Hills"
  },
  {
    "postcode": 4006,
    "name": "Fortitude Valley",
    "value": "Fortitude Valley"
  },
  {
    "postcode": 4006,
    "name": "Herston",
    "value": "Herston"
  },
  {
    "postcode": 4006,
    "name": "Newstead",
    "value": "Newstead"
  },
  {
    "postcode": 4007,
    "name": "Ascot",
    "value": "Ascot"
  },
  {
    "postcode": 4007,
    "name": "Hamilton",
    "value": "Hamilton"
  },
  {
    "postcode": 4008,
    "name": "Pinkenba",
    "value": "Pinkenba"
  },
  {
    "postcode": 4009,
    "name": "Eagle Farm",
    "value": "Eagle Farm"
  },
  {
    "postcode": 4010,
    "name": "Albion",
    "value": "Albion"
  },
  {
    "postcode": 4011,
    "name": "Clayfield",
    "value": "Clayfield"
  },
  {
    "postcode": 4011,
    "name": "Hendra",
    "value": "Hendra"
  },
  {
    "postcode": 4012,
    "name": "Nundah",
    "value": "Nundah"
  },
  {
    "postcode": 4012,
    "name": "Toombul",
    "value": "Toombul"
  },
  {
    "postcode": 4012,
    "name": "Wavell Heights",
    "value": "Wavell Heights"
  },
  {
    "postcode": 4013,
    "name": "Northgate",
    "value": "Northgate"
  },
  {
    "postcode": 4014,
    "name": "Banyo",
    "value": "Banyo"
  },
  {
    "postcode": 4014,
    "name": "Nudgee",
    "value": "Nudgee"
  },
  {
    "postcode": 4014,
    "name": "Virginia",
    "value": "Virginia"
  },
  {
    "postcode": 4017,
    "name": "Brighton",
    "value": "Brighton"
  },
  {
    "postcode": 4017,
    "name": "Deagon",
    "value": "Deagon"
  },
  {
    "postcode": 4017,
    "name": "Sandgate",
    "value": "Sandgate"
  },
  {
    "postcode": 4017,
    "name": "Shorncliffe",
    "value": "Shorncliffe"
  },
  {
    "postcode": 4017,
    "name": "Bracken Ridge",
    "value": "Bracken Ridge"
  },
  {
    "postcode": 4018,
    "name": "Fitzgibbon",
    "value": "Fitzgibbon"
  },
  {
    "postcode": 4018,
    "name": "Taigum",
    "value": "Taigum"
  },
  {
    "postcode": 4019,
    "name": "Clontarf",
    "value": "Clontarf"
  },
  {
    "postcode": 4019,
    "name": "Margate",
    "value": "Margate"
  },
  {
    "postcode": 4019,
    "name": "Woody Point",
    "value": "Woody Point"
  },
  {
    "postcode": 4020,
    "name": "Newport",
    "value": "Newport"
  },
  {
    "postcode": 4020,
    "name": "Redcliffe",
    "value": "Redcliffe"
  },
  {
    "postcode": 4020,
    "name": "Scarborough",
    "value": "Scarborough"
  },
  {
    "postcode": 4021,
    "name": "Kippa-ring",
    "value": "Kippa-ring"
  },
  {
    "postcode": 4022,
    "name": "Rothwell",
    "value": "Rothwell"
  },
  {
    "postcode": 4030,
    "name": "Kalinga",
    "value": "Kalinga"
  },
  {
    "postcode": 4030,
    "name": "Lutwyche",
    "value": "Lutwyche"
  },
  {
    "postcode": 4030,
    "name": "Windsor",
    "value": "Windsor"
  },
  {
    "postcode": 4030,
    "name": "Wooloowin",
    "value": "Wooloowin"
  },
  {
    "postcode": 4031,
    "name": "Gordon Park",
    "value": "Gordon Park"
  },
  {
    "postcode": 4031,
    "name": "Kedron",
    "value": "Kedron"
  },
  {
    "postcode": 4032,
    "name": "Chermside",
    "value": "Chermside"
  },
  {
    "postcode": 4034,
    "name": "Aspley",
    "value": "Aspley"
  },
  {
    "postcode": 4034,
    "name": "Boondall",
    "value": "Boondall"
  },
  {
    "postcode": 4034,
    "name": "Carseldine",
    "value": "Carseldine"
  },
  {
    "postcode": 4034,
    "name": "Geebung",
    "value": "Geebung"
  },
  {
    "postcode": 4034,
    "name": "Zillmere",
    "value": "Zillmere"
  },
  {
    "postcode": 4035,
    "name": "Albany Creek",
    "value": "Albany Creek"
  },
  {
    "postcode": 4035,
    "name": "Bridgeman Downs",
    "value": "Bridgeman Downs"
  },
  {
    "postcode": 4036,
    "name": "Bald Hills",
    "value": "Bald Hills"
  },
  {
    "postcode": 4037,
    "name": "Eatons Hill",
    "value": "Eatons Hill"
  },
  {
    "postcode": 4051,
    "name": "Alderley",
    "value": "Alderley"
  },
  {
    "postcode": 4051,
    "name": "Enoggera",
    "value": "Enoggera"
  },
  {
    "postcode": 4051,
    "name": "Gaythorne",
    "value": "Gaythorne"
  },
  {
    "postcode": 4051,
    "name": "Grange",
    "value": "Grange"
  },
  {
    "postcode": 4051,
    "name": "Newmarket",
    "value": "Newmarket"
  },
  {
    "postcode": 4051,
    "name": "Wilston",
    "value": "Wilston"
  },
  {
    "postcode": 4053,
    "name": "Brookside Centre",
    "value": "Brookside Centre"
  },
  {
    "postcode": 4053,
    "name": "Everton Hills",
    "value": "Everton Hills"
  },
  {
    "postcode": 4053,
    "name": "Everton Park",
    "value": "Everton Park"
  },
  {
    "postcode": 4053,
    "name": "Mcdowall",
    "value": "Mcdowall"
  },
  {
    "postcode": 4053,
    "name": "Mitchelton",
    "value": "Mitchelton"
  },
  {
    "postcode": 4053,
    "name": "Oxford Park",
    "value": "Oxford Park"
  },
  {
    "postcode": 4053,
    "name": "Somerset Hills",
    "value": "Somerset Hills"
  },
  {
    "postcode": 4053,
    "name": "Stafford",
    "value": "Stafford"
  },
  {
    "postcode": 4053,
    "name": "Stafford Heights",
    "value": "Stafford Heights"
  },
  {
    "postcode": 4054,
    "name": "Arana Hills",
    "value": "Arana Hills"
  },
  {
    "postcode": 4054,
    "name": "Keperra",
    "value": "Keperra"
  },
  {
    "postcode": 4055,
    "name": "Ferny Grove",
    "value": "Ferny Grove"
  },
  {
    "postcode": 4055,
    "name": "Ferny Hills",
    "value": "Ferny Hills"
  },
  {
    "postcode": 4055,
    "name": "Upper Kedron",
    "value": "Upper Kedron"
  },
  {
    "postcode": 4055,
    "name": "Bunya",
    "value": "Bunya"
  },
  {
    "postcode": 4059,
    "name": "Kelvin Grove",
    "value": "Kelvin Grove"
  },
  {
    "postcode": 4059,
    "name": "Red Hill",
    "value": "Red Hill"
  },
  {
    "postcode": 4060,
    "name": "Ashgrove",
    "value": "Ashgrove"
  },
  {
    "postcode": 4061,
    "name": "The Gap",
    "value": "The Gap"
  },
  {
    "postcode": 4064,
    "name": "Milton",
    "value": "Milton"
  },
  {
    "postcode": 4064,
    "name": "Paddington",
    "value": "Paddington"
  },
  {
    "postcode": 4065,
    "name": "Bardon",
    "value": "Bardon"
  },
  {
    "postcode": 4066,
    "name": "Auchenflower",
    "value": "Auchenflower"
  },
  {
    "postcode": 4066,
    "name": "Mount Coot-tha",
    "value": "Mount Coot-tha"
  },
  {
    "postcode": 4066,
    "name": "Toowong",
    "value": "Toowong"
  },
  {
    "postcode": 4067,
    "name": "St Lucia",
    "value": "St Lucia"
  },
  {
    "postcode": 4068,
    "name": "Chelmer",
    "value": "Chelmer"
  },
  {
    "postcode": 4068,
    "name": "Indooroopilly",
    "value": "Indooroopilly"
  },
  {
    "postcode": 4068,
    "name": "Taringa",
    "value": "Taringa"
  },
  {
    "postcode": 4069,
    "name": "Brookfield",
    "value": "Brookfield"
  },
  {
    "postcode": 4069,
    "name": "Chapel Hill",
    "value": "Chapel Hill"
  },
  {
    "postcode": 4069,
    "name": "Fig Tree Pocket",
    "value": "Fig Tree Pocket"
  },
  {
    "postcode": 4069,
    "name": "Kenmore",
    "value": "Kenmore"
  },
  {
    "postcode": 4069,
    "name": "Kenmore Hills",
    "value": "Kenmore Hills"
  },
  {
    "postcode": 4069,
    "name": "Pinjarra Hills",
    "value": "Pinjarra Hills"
  },
  {
    "postcode": 4069,
    "name": "Pullenvale",
    "value": "Pullenvale"
  },
  {
    "postcode": 4069,
    "name": "Upper Brookfield",
    "value": "Upper Brookfield"
  },
  {
    "postcode": 4070,
    "name": "Anstead",
    "value": "Anstead"
  },
  {
    "postcode": 4070,
    "name": "Bellbowrie",
    "value": "Bellbowrie"
  },
  {
    "postcode": 4070,
    "name": "Moggill",
    "value": "Moggill"
  },
  {
    "postcode": 4073,
    "name": "Seventeen Mile Rocks",
    "value": "Seventeen Mile Rocks"
  },
  {
    "postcode": 4073,
    "name": "Sinnamon Park",
    "value": "Sinnamon Park"
  },
  {
    "postcode": 4074,
    "name": "Jamboree Heights",
    "value": "Jamboree Heights"
  },
  {
    "postcode": 4074,
    "name": "Jindalee",
    "value": "Jindalee"
  },
  {
    "postcode": 4074,
    "name": "Middle Park",
    "value": "Middle Park"
  },
  {
    "postcode": 4074,
    "name": "Mount Ommaney",
    "value": "Mount Ommaney"
  },
  {
    "postcode": 4074,
    "name": "Riverhills",
    "value": "Riverhills"
  },
  {
    "postcode": 4074,
    "name": "Sumner",
    "value": "Sumner"
  },
  {
    "postcode": 4074,
    "name": "Westlake",
    "value": "Westlake"
  },
  {
    "postcode": 4075,
    "name": "Corinda",
    "value": "Corinda"
  },
  {
    "postcode": 4075,
    "name": "Graceville",
    "value": "Graceville"
  },
  {
    "postcode": 4075,
    "name": "Graceville East",
    "value": "Graceville East"
  },
  {
    "postcode": 4075,
    "name": "Oxley",
    "value": "Oxley"
  },
  {
    "postcode": 4075,
    "name": "Sherwood",
    "value": "Sherwood"
  },
  {
    "postcode": 4076,
    "name": "Darra",
    "value": "Darra"
  },
  {
    "postcode": 4076,
    "name": "Wacol",
    "value": "Wacol"
  },
  {
    "postcode": 4077,
    "name": "Doolandella",
    "value": "Doolandella"
  },
  {
    "postcode": 4077,
    "name": "Durack",
    "value": "Durack"
  },
  {
    "postcode": 4077,
    "name": "Inala",
    "value": "Inala"
  },
  {
    "postcode": 4077,
    "name": "Inala Heights",
    "value": "Inala Heights"
  },
  {
    "postcode": 4077,
    "name": "Richlands",
    "value": "Richlands"
  },
  {
    "postcode": 4078,
    "name": "Ellen Grove",
    "value": "Ellen Grove"
  },
  {
    "postcode": 4078,
    "name": "Forest Lake",
    "value": "Forest Lake"
  },
  {
    "postcode": 4101,
    "name": "Highgate Hill",
    "value": "Highgate Hill"
  },
  {
    "postcode": 4101,
    "name": "South Brisbane",
    "value": "South Brisbane"
  },
  {
    "postcode": 4101,
    "name": "West End",
    "value": "West End"
  },
  {
    "postcode": 4102,
    "name": "Dutton Park",
    "value": "Dutton Park"
  },
  {
    "postcode": 4102,
    "name": "Woolloongabba",
    "value": "Woolloongabba"
  },
  {
    "postcode": 4103,
    "name": "Annerley",
    "value": "Annerley"
  },
  {
    "postcode": 4103,
    "name": "Fairfield",
    "value": "Fairfield"
  },
  {
    "postcode": 4103,
    "name": "Fairfield Gardens",
    "value": "Fairfield Gardens"
  },
  {
    "postcode": 4104,
    "name": "Yeronga",
    "value": "Yeronga"
  },
  {
    "postcode": 4105,
    "name": "Yeerongpilly",
    "value": "Yeerongpilly"
  },
  {
    "postcode": 4105,
    "name": "Moorooka",
    "value": "Moorooka"
  },
  {
    "postcode": 4105,
    "name": "Tennyson",
    "value": "Tennyson"
  },
  {
    "postcode": 4106,
    "name": "Rocklea",
    "value": "Rocklea"
  },
  {
    "postcode": 4107,
    "name": "Salisbury",
    "value": "Salisbury"
  },
  {
    "postcode": 4108,
    "name": "Archerfield",
    "value": "Archerfield"
  },
  {
    "postcode": 4108,
    "name": "Coopers Plains",
    "value": "Coopers Plains"
  },
  {
    "postcode": 4109,
    "name": "Macgregor",
    "value": "Macgregor"
  },
  {
    "postcode": 4109,
    "name": "Robertson",
    "value": "Robertson"
  },
  {
    "postcode": 4109,
    "name": "Sunnybank",
    "value": "Sunnybank"
  },
  {
    "postcode": 4109,
    "name": "Sunnybank Hills",
    "value": "Sunnybank Hills"
  },
  {
    "postcode": 4110,
    "name": "Acacia Ridge",
    "value": "Acacia Ridge"
  },
  {
    "postcode": 4110,
    "name": "Heathwood",
    "value": "Heathwood"
  },
  {
    "postcode": 4110,
    "name": "Larapinta",
    "value": "Larapinta"
  },
  {
    "postcode": 4110,
    "name": "Pallara",
    "value": "Pallara"
  },
  {
    "postcode": 4110,
    "name": "Willawong",
    "value": "Willawong"
  },
  {
    "postcode": 4111,
    "name": "Nathan",
    "value": "Nathan"
  },
  {
    "postcode": 4112,
    "name": "Kuraby",
    "value": "Kuraby"
  },
  {
    "postcode": 4113,
    "name": "Eight Mile Plains",
    "value": "Eight Mile Plains"
  },
  {
    "postcode": 4113,
    "name": "Runcorn",
    "value": "Runcorn"
  },
  {
    "postcode": 4114,
    "name": "Kingston",
    "value": "Kingston"
  },
  {
    "postcode": 4114,
    "name": "Logan Central",
    "value": "Logan Central"
  },
  {
    "postcode": 4114,
    "name": "Woodridge",
    "value": "Woodridge"
  },
  {
    "postcode": 4115,
    "name": "Algester",
    "value": "Algester"
  },
  {
    "postcode": 4115,
    "name": "Parkinson",
    "value": "Parkinson"
  },
  {
    "postcode": 4116,
    "name": "Calamvale",
    "value": "Calamvale"
  },
  {
    "postcode": 4116,
    "name": "Drewvale",
    "value": "Drewvale"
  },
  {
    "postcode": 4116,
    "name": "Stretton",
    "value": "Stretton"
  },
  {
    "postcode": 4117,
    "name": "Berrinba",
    "value": "Berrinba"
  },
  {
    "postcode": 4117,
    "name": "Karawatha",
    "value": "Karawatha"
  },
  {
    "postcode": 4118,
    "name": "Browns Plains",
    "value": "Browns Plains"
  },
  {
    "postcode": 4118,
    "name": "Forestdale",
    "value": "Forestdale"
  },
  {
    "postcode": 4118,
    "name": "Heritage Park",
    "value": "Heritage Park"
  },
  {
    "postcode": 4118,
    "name": "Hillcrest",
    "value": "Hillcrest"
  },
  {
    "postcode": 4118,
    "name": "Regents Park",
    "value": "Regents Park"
  },
  {
    "postcode": 4119,
    "name": "Underwood",
    "value": "Underwood"
  },
  {
    "postcode": 4120,
    "name": "Greenslopes",
    "value": "Greenslopes"
  },
  {
    "postcode": 4120,
    "name": "Stones Corner",
    "value": "Stones Corner"
  },
  {
    "postcode": 4121,
    "name": "Holland Park",
    "value": "Holland Park"
  },
  {
    "postcode": 4121,
    "name": "Tarragindi",
    "value": "Tarragindi"
  },
  {
    "postcode": 4121,
    "name": "Wellers Hill",
    "value": "Wellers Hill"
  },
  {
    "postcode": 4122,
    "name": "Mansfield",
    "value": "Mansfield"
  },
  {
    "postcode": 4122,
    "name": "Mount Gravatt",
    "value": "Mount Gravatt"
  },
  {
    "postcode": 4122,
    "name": "Mount Gravatt East",
    "value": "Mount Gravatt East"
  },
  {
    "postcode": 4122,
    "name": "Upper Mount Gravatt",
    "value": "Upper Mount Gravatt"
  },
  {
    "postcode": 4122,
    "name": "Wishart",
    "value": "Wishart"
  },
  {
    "postcode": 4123,
    "name": "Rochedale",
    "value": "Rochedale"
  },
  {
    "postcode": 4124,
    "name": "Boronia Heights",
    "value": "Boronia Heights"
  },
  {
    "postcode": 4124,
    "name": "Greenbank",
    "value": "Greenbank"
  },
  {
    "postcode": 4124,
    "name": "Lyons",
    "value": "Lyons"
  },
  {
    "postcode": 4124,
    "name": "New Beith",
    "value": "New Beith"
  },
  {
    "postcode": 4124,
    "name": "Silverbark Ridge",
    "value": "Silverbark Ridge"
  },
  {
    "postcode": 4125,
    "name": "Manruben",
    "value": "Manruben"
  },
  {
    "postcode": 4125,
    "name": "Park Ridge",
    "value": "Park Ridge"
  },
  {
    "postcode": 4127,
    "name": "Daisy Hill",
    "value": "Daisy Hill"
  },
  {
    "postcode": 4127,
    "name": "Priestdale",
    "value": "Priestdale"
  },
  {
    "postcode": 4127,
    "name": "Slacks Creek",
    "value": "Slacks Creek"
  },
  {
    "postcode": 4127,
    "name": "Springwood",
    "value": "Springwood"
  },
  {
    "postcode": 4128,
    "name": "Shailer Park",
    "value": "Shailer Park"
  },
  {
    "postcode": 4128,
    "name": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "postcode": 4129,
    "name": "Loganholme",
    "value": "Loganholme"
  },
  {
    "postcode": 4130,
    "name": "Carbrook",
    "value": "Carbrook"
  },
  {
    "postcode": 4130,
    "name": "Cornubia",
    "value": "Cornubia"
  },
  {
    "postcode": 4131,
    "name": "Loganlea",
    "value": "Loganlea"
  },
  {
    "postcode": 4131,
    "name": "Meadowbrook",
    "value": "Meadowbrook"
  },
  {
    "postcode": 4132,
    "name": "Crestmead",
    "value": "Crestmead"
  },
  {
    "postcode": 4132,
    "name": "Marsden",
    "value": "Marsden"
  },
  {
    "postcode": 4133,
    "name": "Chambers Flat",
    "value": "Chambers Flat"
  },
  {
    "postcode": 4133,
    "name": "Logan Reserve",
    "value": "Logan Reserve"
  },
  {
    "postcode": 4133,
    "name": "Waterford",
    "value": "Waterford"
  },
  {
    "postcode": 4151,
    "name": "Coorparoo",
    "value": "Coorparoo"
  },
  {
    "postcode": 4152,
    "name": "Camp Hill",
    "value": "Camp Hill"
  },
  {
    "postcode": 4152,
    "name": "Carina",
    "value": "Carina"
  },
  {
    "postcode": 4152,
    "name": "Carina Heights",
    "value": "Carina Heights"
  },
  {
    "postcode": 4152,
    "name": "Carindale",
    "value": "Carindale"
  },
  {
    "postcode": 4153,
    "name": "Belmont",
    "value": "Belmont"
  },
  {
    "postcode": 4154,
    "name": "Gumdale",
    "value": "Gumdale"
  },
  {
    "postcode": 4154,
    "name": "Ransome",
    "value": "Ransome"
  },
  {
    "postcode": 4154,
    "name": "Wakerley",
    "value": "Wakerley"
  },
  {
    "postcode": 4155,
    "name": "Chandler",
    "value": "Chandler"
  },
  {
    "postcode": 4156,
    "name": "Burbank",
    "value": "Burbank"
  },
  {
    "postcode": 4156,
    "name": "Mackenzie",
    "value": "Mackenzie"
  },
  {
    "postcode": 4157,
    "name": "Capalaba",
    "value": "Capalaba"
  },
  {
    "postcode": 4157,
    "name": "Sheldon",
    "value": "Sheldon"
  },
  {
    "postcode": 4158,
    "name": "Thorneside",
    "value": "Thorneside"
  },
  {
    "postcode": 4159,
    "name": "Birkdale",
    "value": "Birkdale"
  },
  {
    "postcode": 4160,
    "name": "Ormiston",
    "value": "Ormiston"
  },
  {
    "postcode": 4160,
    "name": "Wellington Point",
    "value": "Wellington Point"
  },
  {
    "postcode": 4161,
    "name": "Alexandra Hills",
    "value": "Alexandra Hills"
  },
  {
    "postcode": 4163,
    "name": "Cleveland",
    "value": "Cleveland"
  },
  {
    "postcode": 4164,
    "name": "Thornlands",
    "value": "Thornlands"
  },
  {
    "postcode": 4165,
    "name": "Mount Cotton",
    "value": "Mount Cotton"
  },
  {
    "postcode": 4165,
    "name": "Redland Bay",
    "value": "Redland Bay"
  },
  {
    "postcode": 4165,
    "name": "Victoria Point",
    "value": "Victoria Point"
  },
  {
    "postcode": 4169,
    "name": "East Brisbane",
    "value": "East Brisbane"
  },
  {
    "postcode": 4169,
    "name": "Kangaroo Point",
    "value": "Kangaroo Point"
  },
  {
    "postcode": 4170,
    "name": "Cannon Hill",
    "value": "Cannon Hill"
  },
  {
    "postcode": 4170,
    "name": "Morningside",
    "value": "Morningside"
  },
  {
    "postcode": 4170,
    "name": "Norman Park",
    "value": "Norman Park"
  },
  {
    "postcode": 4170,
    "name": "Seven Hills",
    "value": "Seven Hills"
  },
  {
    "postcode": 4171,
    "name": "Balmoral",
    "value": "Balmoral"
  },
  {
    "postcode": 4171,
    "name": "Bulimba",
    "value": "Bulimba"
  },
  {
    "postcode": 4171,
    "name": "Hawthorne",
    "value": "Hawthorne"
  },
  {
    "postcode": 4172,
    "name": "Murarrie",
    "value": "Murarrie"
  },
  {
    "postcode": 4173,
    "name": "Tingalpa",
    "value": "Tingalpa"
  },
  {
    "postcode": 4174,
    "name": "Hemmant",
    "value": "Hemmant"
  },
  {
    "postcode": 4178,
    "name": "Lytton",
    "value": "Lytton"
  },
  {
    "postcode": 4178,
    "name": "Port Of Brisbane",
    "value": "Port Of Brisbane"
  },
  {
    "postcode": 4178,
    "name": "Wynnum",
    "value": "Wynnum"
  },
  {
    "postcode": 4179,
    "name": "Lota",
    "value": "Lota"
  },
  {
    "postcode": 4179,
    "name": "Manly",
    "value": "Manly"
  },
  {
    "postcode": 4179,
    "name": "Manly West",
    "value": "Manly West"
  },
  {
    "postcode": 4205,
    "name": "Bethania",
    "value": "Bethania"
  },
  {
    "postcode": 4207,
    "name": "Alberton",
    "value": "Alberton"
  },
  {
    "postcode": 4207,
    "name": "Bahrs Scrub",
    "value": "Bahrs Scrub"
  },
  {
    "postcode": 4207,
    "name": "Bannockburn",
    "value": "Bannockburn"
  },
  {
    "postcode": 4207,
    "name": "Beenleigh",
    "value": "Beenleigh"
  },
  {
    "postcode": 4207,
    "name": "Belivah",
    "value": "Belivah"
  },
  {
    "postcode": 4207,
    "name": "Buccan",
    "value": "Buccan"
  },
  {
    "postcode": 4207,
    "name": "Cedar Creek",
    "value": "Cedar Creek"
  },
  {
    "postcode": 4207,
    "name": "Eagleby",
    "value": "Eagleby"
  },
  {
    "postcode": 4207,
    "name": "Edens Landing",
    "value": "Edens Landing"
  },
  {
    "postcode": 4207,
    "name": "Holmview",
    "value": "Holmview"
  },
  {
    "postcode": 4207,
    "name": "Kairabah",
    "value": "Kairabah"
  },
  {
    "postcode": 4207,
    "name": "Logan Village",
    "value": "Logan Village"
  },
  {
    "postcode": 4207,
    "name": "Luscombe",
    "value": "Luscombe"
  },
  {
    "postcode": 4207,
    "name": "Mount Warren Park",
    "value": "Mount Warren Park"
  },
  {
    "postcode": 4207,
    "name": "Stapylton",
    "value": "Stapylton"
  },
  {
    "postcode": 4207,
    "name": "Steiglitz",
    "value": "Steiglitz"
  },
  {
    "postcode": 4207,
    "name": "Windaroo",
    "value": "Windaroo"
  },
  {
    "postcode": 4207,
    "name": "Wolffdene",
    "value": "Wolffdene"
  },
  {
    "postcode": 4207,
    "name": "Woongoolba",
    "value": "Woongoolba"
  },
  {
    "postcode": 4207,
    "name": "Yarrabilba",
    "value": "Yarrabilba"
  },
  {
    "postcode": 4207,
    "name": "Yatala",
    "value": "Yatala"
  },
  {
    "postcode": 4208,
    "name": "Gilberton",
    "value": "Gilberton"
  },
  {
    "postcode": 4208,
    "name": "Jacobs Well",
    "value": "Jacobs Well"
  },
  {
    "postcode": 4208,
    "name": "Kingsholme",
    "value": "Kingsholme"
  },
  {
    "postcode": 4208,
    "name": "Norwell",
    "value": "Norwell"
  },
  {
    "postcode": 4208,
    "name": "Ormeau",
    "value": "Ormeau"
  },
  {
    "postcode": 4208,
    "name": "Ormeau Hills",
    "value": "Ormeau Hills"
  },
  {
    "postcode": 4209,
    "name": "Coomera",
    "value": "Coomera"
  },
  {
    "postcode": 4209,
    "name": "Pimpama",
    "value": "Pimpama"
  },
  {
    "postcode": 4209,
    "name": "Upper Coomera",
    "value": "Upper Coomera"
  },
  {
    "postcode": 4209,
    "name": "Willow Vale",
    "value": "Willow Vale"
  },
  {
    "postcode": 4210,
    "name": "Guanaba",
    "value": "Guanaba"
  },
  {
    "postcode": 4210,
    "name": "Maudsland",
    "value": "Maudsland"
  },
  {
    "postcode": 4210,
    "name": "Oxenford",
    "value": "Oxenford"
  },
  {
    "postcode": 4210,
    "name": "Studio Village",
    "value": "Studio Village"
  },
  {
    "postcode": 4210,
    "name": "Wongawallan",
    "value": "Wongawallan"
  },
  {
    "postcode": 4211,
    "name": "Advancetown",
    "value": "Advancetown"
  },
  {
    "postcode": 4211,
    "name": "Carrara",
    "value": "Carrara"
  },
  {
    "postcode": 4211,
    "name": "Clagiraba",
    "value": "Clagiraba"
  },
  {
    "postcode": 4211,
    "name": "Gaven",
    "value": "Gaven"
  },
  {
    "postcode": 4211,
    "name": "Gilston",
    "value": "Gilston"
  },
  {
    "postcode": 4211,
    "name": "Highland Park",
    "value": "Highland Park"
  },
  {
    "postcode": 4211,
    "name": "Lower Beechmont",
    "value": "Lower Beechmont"
  },
  {
    "postcode": 4211,
    "name": "Mount Nathan",
    "value": "Mount Nathan"
  },
  {
    "postcode": 4211,
    "name": "Nerang",
    "value": "Nerang"
  },
  {
    "postcode": 4211,
    "name": "Pacific Pines",
    "value": "Pacific Pines"
  },
  {
    "postcode": 4212,
    "name": "Helensvale",
    "value": "Helensvale"
  },
  {
    "postcode": 4212,
    "name": "Hope Island",
    "value": "Hope Island"
  },
  {
    "postcode": 4212,
    "name": "Sanctuary Cove",
    "value": "Sanctuary Cove"
  },
  {
    "postcode": 4213,
    "name": "Austinville",
    "value": "Austinville"
  },
  {
    "postcode": 4213,
    "name": "Bonogin",
    "value": "Bonogin"
  },
  {
    "postcode": 4213,
    "name": "Mudgeeraba",
    "value": "Mudgeeraba"
  },
  {
    "postcode": 4213,
    "name": "Neranwood",
    "value": "Neranwood"
  },
  {
    "postcode": 4213,
    "name": "Springbrook",
    "value": "Springbrook"
  },
  {
    "postcode": 4213,
    "name": "Tallai",
    "value": "Tallai"
  },
  {
    "postcode": 4213,
    "name": "Worongary",
    "value": "Worongary"
  },
  {
    "postcode": 4214,
    "name": "Arundel",
    "value": "Arundel"
  },
  {
    "postcode": 4214,
    "name": "Ashmore",
    "value": "Ashmore"
  },
  {
    "postcode": 4214,
    "name": "Molendinar",
    "value": "Molendinar"
  },
  {
    "postcode": 4214,
    "name": "Parkwood",
    "value": "Parkwood"
  },
  {
    "postcode": 4215,
    "name": "Chirn Park",
    "value": "Chirn Park"
  },
  {
    "postcode": 4215,
    "name": "Labrador",
    "value": "Labrador"
  },
  {
    "postcode": 4215,
    "name": "Southport",
    "value": "Southport"
  },
  {
    "postcode": 4215,
    "name": "Sundale",
    "value": "Sundale"
  },
  {
    "postcode": 4216,
    "name": "Biggera Waters",
    "value": "Biggera Waters"
  },
  {
    "postcode": 4216,
    "name": "Coombabah",
    "value": "Coombabah"
  },
  {
    "postcode": 4216,
    "name": "Hollywell",
    "value": "Hollywell"
  },
  {
    "postcode": 4216,
    "name": "Paradise Point",
    "value": "Paradise Point"
  },
  {
    "postcode": 4216,
    "name": "Runaway Bay",
    "value": "Runaway Bay"
  },
  {
    "postcode": 4216,
    "name": "South Stradbroke",
    "value": "South Stradbroke"
  },
  {
    "postcode": 4217,
    "name": "Benowa",
    "value": "Benowa"
  },
  {
    "postcode": 4217,
    "name": "Bundall",
    "value": "Bundall"
  },
  {
    "postcode": 4217,
    "name": "Chevron Island",
    "value": "Chevron Island"
  },
  {
    "postcode": 4217,
    "name": "Isle Of Capri",
    "value": "Isle Of Capri"
  },
  {
    "postcode": 4217,
    "name": "Main Beach",
    "value": "Main Beach"
  },
  {
    "postcode": 4217,
    "name": "Surfers Paradise",
    "value": "Surfers Paradise"
  },
  {
    "postcode": 4218,
    "name": "Broadbeach",
    "value": "Broadbeach"
  },
  {
    "postcode": 4218,
    "name": "Broadbeach Waters",
    "value": "Broadbeach Waters"
  },
  {
    "postcode": 4218,
    "name": "Mermaid Beach",
    "value": "Mermaid Beach"
  },
  {
    "postcode": 4218,
    "name": "Mermaid Waters",
    "value": "Mermaid Waters"
  },
  {
    "postcode": 4218,
    "name": "Nobby Beach",
    "value": "Nobby Beach"
  },
  {
    "postcode": 4220,
    "name": "Burleigh Heads",
    "value": "Burleigh Heads"
  },
  {
    "postcode": 4220,
    "name": "Burleigh Waters",
    "value": "Burleigh Waters"
  },
  {
    "postcode": 4220,
    "name": "Miami",
    "value": "Miami"
  },
  {
    "postcode": 4221,
    "name": "Elanora",
    "value": "Elanora"
  },
  {
    "postcode": 4221,
    "name": "Palm Beach",
    "value": "Palm Beach"
  },
  {
    "postcode": 4222,
    "name": "Southport",
    "value": "Southport"
  },
  {
    "postcode": 4223,
    "name": "Currumbin",
    "value": "Currumbin"
  },
  {
    "postcode": 4223,
    "name": "Currumbin Valley",
    "value": "Currumbin Valley"
  },
  {
    "postcode": 4223,
    "name": "Currumbin Waters",
    "value": "Currumbin Waters"
  },
  {
    "postcode": 4224,
    "name": "Tugun",
    "value": "Tugun"
  },
  {
    "postcode": 4225,
    "name": "Bilinga",
    "value": "Bilinga"
  },
  {
    "postcode": 4225,
    "name": "Coolangatta",
    "value": "Coolangatta"
  },
  {
    "postcode": 4226,
    "name": "Clear Island Waters",
    "value": "Clear Island Waters"
  },
  {
    "postcode": 4226,
    "name": "Merrimac",
    "value": "Merrimac"
  },
  {
    "postcode": 4226,
    "name": "Robina",
    "value": "Robina"
  },
  {
    "postcode": 4227,
    "name": "Reedy Creek",
    "value": "Reedy Creek"
  },
  {
    "postcode": 4227,
    "name": "Varsity Lakes",
    "value": "Varsity Lakes"
  },
  {
    "postcode": 4228,
    "name": "Tallebudgera",
    "value": "Tallebudgera"
  },
  {
    "postcode": 4230,
    "name": "Robina Town Centre",
    "value": "Robina Town Centre"
  },
  {
    "postcode": 4270,
    "name": "Tamborine",
    "value": "Tamborine"
  },
  {
    "postcode": 4271,
    "name": "Eagle Heights",
    "value": "Eagle Heights"
  },
  {
    "postcode": 4280,
    "name": "Flagstone",
    "value": "Flagstone"
  },
  {
    "postcode": 4280,
    "name": "Jimboomba",
    "value": "Jimboomba"
  },
  {
    "postcode": 4280,
    "name": "Maclean",
    "value": "Maclean"
  },
  {
    "postcode": 4300,
    "name": "Augustine Heights",
    "value": "Augustine Heights"
  },
  {
    "postcode": 4300,
    "name": "Bellbird Park",
    "value": "Bellbird Park"
  },
  {
    "postcode": 4300,
    "name": "Brookwater",
    "value": "Brookwater"
  },
  {
    "postcode": 4300,
    "name": "Camira",
    "value": "Camira"
  },
  {
    "postcode": 4300,
    "name": "Carole Park",
    "value": "Carole Park"
  },
  {
    "postcode": 4300,
    "name": "Gailes",
    "value": "Gailes"
  },
  {
    "postcode": 4300,
    "name": "Goodna",
    "value": "Goodna"
  },
  {
    "postcode": 4300,
    "name": "Springfield",
    "value": "Springfield"
  },
  {
    "postcode": 4300,
    "name": "Springfield Lakes",
    "value": "Springfield Lakes"
  },
  {
    "postcode": 4300,
    "name": "Spring Mountain",
    "value": "Spring Mountain"
  },
  {
    "postcode": 4301,
    "name": "Collingwood Park",
    "value": "Collingwood Park"
  },
  {
    "postcode": 4301,
    "name": "Redbank",
    "value": "Redbank"
  },
  {
    "postcode": 4301,
    "name": "Redbank Plains",
    "value": "Redbank Plains"
  },
  {
    "postcode": 4303,
    "name": "Dinmore",
    "value": "Dinmore"
  },
  {
    "postcode": 4303,
    "name": "New Chum",
    "value": "New Chum"
  },
  {
    "postcode": 4303,
    "name": "Riverview",
    "value": "Riverview"
  },
  {
    "postcode": 4304,
    "name": "Blackstone",
    "value": "Blackstone"
  },
  {
    "postcode": 4304,
    "name": "Booval",
    "value": "Booval"
  },
  {
    "postcode": 4304,
    "name": "Booval Fair",
    "value": "Booval Fair"
  },
  {
    "postcode": 4304,
    "name": "Bundamba",
    "value": "Bundamba"
  },
  {
    "postcode": 4304,
    "name": "Ebbw Vale",
    "value": "Ebbw Vale"
  },
  {
    "postcode": 4304,
    "name": "Silkstone",
    "value": "Silkstone"
  },
  {
    "postcode": 4305,
    "name": "Basin Pocket",
    "value": "Basin Pocket"
  },
  {
    "postcode": 4305,
    "name": "Brassall",
    "value": "Brassall"
  },
  {
    "postcode": 4305,
    "name": "Bremer",
    "value": "Bremer"
  },
  {
    "postcode": 4305,
    "name": "Churchill",
    "value": "Churchill"
  },
  {
    "postcode": 4305,
    "name": "Coalfalls",
    "value": "Coalfalls"
  },
  {
    "postcode": 4305,
    "name": "East Ipswich",
    "value": "East Ipswich"
  },
  {
    "postcode": 4305,
    "name": "Eastern Heights",
    "value": "Eastern Heights"
  },
  {
    "postcode": 4305,
    "name": "Flinders View",
    "value": "Flinders View"
  },
  {
    "postcode": 4305,
    "name": "Ipswich",
    "value": "Ipswich"
  },
  {
    "postcode": 4305,
    "name": "Leichhardt",
    "value": "Leichhardt"
  },
  {
    "postcode": 4305,
    "name": "Limestone Ridges",
    "value": "Limestone Ridges"
  },
  {
    "postcode": 4305,
    "name": "Moores Pocket",
    "value": "Moores Pocket"
  },
  {
    "postcode": 4305,
    "name": "Newtown",
    "value": "Newtown"
  },
  {
    "postcode": 4305,
    "name": "One Mile",
    "value": "One Mile"
  },
  {
    "postcode": 4305,
    "name": "Raceview",
    "value": "Raceview"
  },
  {
    "postcode": 4305,
    "name": "Sadliers Crossing",
    "value": "Sadliers Crossing"
  },
  {
    "postcode": 4305,
    "name": "Tivoli",
    "value": "Tivoli"
  },
  {
    "postcode": 4305,
    "name": "Woodend",
    "value": "Woodend"
  },
  {
    "postcode": 4305,
    "name": "Wulkuraka",
    "value": "Wulkuraka"
  },
  {
    "postcode": 4305,
    "name": "Yamanto",
    "value": "Yamanto"
  },
  {
    "postcode": 4306,
    "name": "Amberley",
    "value": "Amberley"
  },
  {
    "postcode": 4306,
    "name": "Barellan Point",
    "value": "Barellan Point"
  },
  {
    "postcode": 4306,
    "name": "Blacksoil",
    "value": "Blacksoil"
  },
  {
    "postcode": 4306,
    "name": "Chuwar",
    "value": "Chuwar"
  },
  {
    "postcode": 4306,
    "name": "Deebing Heights",
    "value": "Deebing Heights"
  },
  {
    "postcode": 4306,
    "name": "Karalee",
    "value": "Karalee"
  },
  {
    "postcode": 4306,
    "name": "Karana Downs",
    "value": "Karana Downs"
  },
  {
    "postcode": 4306,
    "name": "Karrabin",
    "value": "Karrabin"
  },
  {
    "postcode": 4306,
    "name": "Mount Crosby",
    "value": "Mount Crosby"
  },
  {
    "postcode": 4306,
    "name": "Muirlea",
    "value": "Muirlea"
  },
  {
    "postcode": 4306,
    "name": "Purga",
    "value": "Purga"
  },
  {
    "postcode": 4306,
    "name": "Ripley",
    "value": "Ripley"
  },
  {
    "postcode": 4306,
    "name": "South Ripley",
    "value": "South Ripley"
  },
  {
    "postcode": 4306,
    "name": "Swanbank",
    "value": "Swanbank"
  },
  {
    "postcode": 4306,
    "name": "Walloon",
    "value": "Walloon"
  },
  {
    "postcode": 4306,
    "name": "Willowbank",
    "value": "Willowbank"
  },
  {
    "postcode": 4500,
    "name": "Bray Park",
    "value": "Bray Park"
  },
  {
    "postcode": 4500,
    "name": "Brendale",
    "value": "Brendale"
  },
  {
    "postcode": 4500,
    "name": "Cashmere",
    "value": "Cashmere"
  },
  {
    "postcode": 4500,
    "name": "Clear Mountain",
    "value": "Clear Mountain"
  },
  {
    "postcode": 4500,
    "name": "Joyner",
    "value": "Joyner"
  },
  {
    "postcode": 4500,
    "name": "Strathpine",
    "value": "Strathpine"
  },
  {
    "postcode": 4500,
    "name": "Warner",
    "value": "Warner"
  },
  {
    "postcode": 4501,
    "name": "Lawnton",
    "value": "Lawnton"
  },
  {
    "postcode": 4502,
    "name": "Petrie",
    "value": "Petrie"
  },
  {
    "postcode": 4503,
    "name": "Dakabin",
    "value": "Dakabin"
  },
  {
    "postcode": 4503,
    "name": "Griffin",
    "value": "Griffin"
  },
  {
    "postcode": 4503,
    "name": "Kallangur",
    "value": "Kallangur"
  },
  {
    "postcode": 4503,
    "name": "Kurwongbah",
    "value": "Kurwongbah"
  },
  {
    "postcode": 4503,
    "name": "Murrumba Downs",
    "value": "Murrumba Downs"
  },
  {
    "postcode": 4503,
    "name": "Whiteside",
    "value": "Whiteside"
  },
  {
    "postcode": 4504,
    "name": "Narangba",
    "value": "Narangba"
  },
  {
    "postcode": 4505,
    "name": "Burpengary",
    "value": "Burpengary"
  },
  {
    "postcode": 4506,
    "name": "Moorina",
    "value": "Moorina"
  },
  {
    "postcode": 4506,
    "name": "Morayfield",
    "value": "Morayfield"
  },
  {
    "postcode": 4507,
    "name": "Banksia Beach",
    "value": "Banksia Beach"
  },
  {
    "postcode": 4507,
    "name": "Bellara",
    "value": "Bellara"
  },
  {
    "postcode": 4507,
    "name": "Bongaree",
    "value": "Bongaree"
  },
  {
    "postcode": 4507,
    "name": "Bribie Island",
    "value": "Bribie Island"
  },
  {
    "postcode": 4507,
    "name": "Welsby",
    "value": "Welsby"
  },
  {
    "postcode": 4507,
    "name": "White Patch",
    "value": "White Patch"
  },
  {
    "postcode": 4507,
    "name": "Woorim",
    "value": "Woorim"
  },
  {
    "postcode": 4508,
    "name": "Deception Bay",
    "value": "Deception Bay"
  },
  {
    "postcode": 4509,
    "name": "Mango Hill",
    "value": "Mango Hill"
  },
  {
    "postcode": 4509,
    "name": "North Lakes",
    "value": "North Lakes"
  },
  {
    "postcode": 4510,
    "name": "Beachmere",
    "value": "Beachmere"
  },
  {
    "postcode": 4510,
    "name": "Bellmere",
    "value": "Bellmere"
  },
  {
    "postcode": 4510,
    "name": "Caboolture",
    "value": "Caboolture"
  },
  {
    "postcode": 4510,
    "name": "Donnybrook",
    "value": "Donnybrook"
  },
  {
    "postcode": 4510,
    "name": "Meldale",
    "value": "Meldale"
  },
  {
    "postcode": 4510,
    "name": "Moodlu",
    "value": "Moodlu"
  },
  {
    "postcode": 4510,
    "name": "Rocksberg",
    "value": "Rocksberg"
  },
  {
    "postcode": 4510,
    "name": "Toorbul",
    "value": "Toorbul"
  },
  {
    "postcode": 4510,
    "name": "Upper Caboolture",
    "value": "Upper Caboolture"
  },
  {
    "postcode": 4511,
    "name": "Godwin Beach",
    "value": "Godwin Beach"
  },
  {
    "postcode": 4511,
    "name": "Ningi",
    "value": "Ningi"
  },
  {
    "postcode": 4511,
    "name": "Sandstone Point",
    "value": "Sandstone Point"
  },
  {
    "postcode": 4512,
    "name": "Bracalba",
    "value": "Bracalba"
  },
  {
    "postcode": 4512,
    "name": "Wamuran",
    "value": "Wamuran"
  },
  {
    "postcode": 4514,
    "name": "D'aguilar",
    "value": "D'aguilar"
  },
  {
    "postcode": 4514,
    "name": "Delaneys Creek",
    "value": "Delaneys Creek"
  },
  {
    "postcode": 4514,
    "name": "Stanmore",
    "value": "Stanmore"
  },
  {
    "postcode": 4514,
    "name": "Woodford",
    "value": "Woodford"
  },
  {
    "postcode": 4516,
    "name": "Elimbah",
    "value": "Elimbah"
  },
  {
    "postcode": 4517,
    "name": "Beerburrum",
    "value": "Beerburrum"
  },
  {
    "postcode": 4518,
    "name": "Glass House Mountains",
    "value": "Glass House Mountains"
  },
  {
    "postcode": 4519,
    "name": "Beerwah",
    "value": "Beerwah"
  },
  {
    "postcode": 4519,
    "name": "Coochin Creek",
    "value": "Coochin Creek"
  },
  {
    "postcode": 4520,
    "name": "Camp Mountain",
    "value": "Camp Mountain"
  },
  {
    "postcode": 4520,
    "name": "Closeburn",
    "value": "Closeburn"
  },
  {
    "postcode": 4520,
    "name": "Draper",
    "value": "Draper"
  },
  {
    "postcode": 4520,
    "name": "Enoggera",
    "value": "Enoggera"
  },
  {
    "postcode": 4520,
    "name": "Mount Samson",
    "value": "Mount Samson"
  },
  {
    "postcode": 4520,
    "name": "Samford",
    "value": "Samford"
  },
  {
    "postcode": 4520,
    "name": "Samford Valley",
    "value": "Samford Valley"
  },
  {
    "postcode": 4520,
    "name": "Samford Village",
    "value": "Samford Village"
  },
  {
    "postcode": 4520,
    "name": "Samsonvale",
    "value": "Samsonvale"
  },
  {
    "postcode": 4520,
    "name": "Yugar",
    "value": "Yugar"
  },
  {
    "postcode": 4521,
    "name": "Campbells Pocket",
    "value": "Campbells Pocket"
  },
  {
    "postcode": 4521,
    "name": "Dayboro",
    "value": "Dayboro"
  },
  {
    "postcode": 4521,
    "name": "King Scrub",
    "value": "King Scrub"
  },
  {
    "postcode": 4521,
    "name": "Laceys Creek",
    "value": "Laceys Creek"
  },
  {
    "postcode": 4521,
    "name": "Mount Mee",
    "value": "Mount Mee"
  },
  {
    "postcode": 4521,
    "name": "Mount Pleasant",
    "value": "Mount Pleasant"
  },
  {
    "postcode": 4521,
    "name": "Ocean View",
    "value": "Ocean View"
  },
  {
    "postcode": 4521,
    "name": "Rush Creek",
    "value": "Rush Creek"
  },
  {
    "postcode": 4550,
    "name": "Landsborough",
    "value": "Landsborough"
  },
  {
    "postcode": 4550,
    "name": "Mount Mellum",
    "value": "Mount Mellum"
  },
  {
    "postcode": 4551,
    "name": "Aroona",
    "value": "Aroona"
  },
  {
    "postcode": 4551,
    "name": "Battery Hill",
    "value": "Battery Hill"
  },
  {
    "postcode": 4551,
    "name": "Bells Creek",
    "value": "Bells Creek"
  },
  {
    "postcode": 4551,
    "name": "Caloundra",
    "value": "Caloundra"
  },
  {
    "postcode": 4551,
    "name": "Caloundra West",
    "value": "Caloundra West"
  },
  {
    "postcode": 4551,
    "name": "Currimundi",
    "value": "Currimundi"
  },
  {
    "postcode": 4551,
    "name": "Dicky Beach",
    "value": "Dicky Beach"
  },
  {
    "postcode": 4551,
    "name": "Golden Beach",
    "value": "Golden Beach"
  },
  {
    "postcode": 4551,
    "name": "Kings Beach",
    "value": "Kings Beach"
  },
  {
    "postcode": 4551,
    "name": "Little Mountain",
    "value": "Little Mountain"
  },
  {
    "postcode": 4551,
    "name": "Meridan Plains",
    "value": "Meridan Plains"
  },
  {
    "postcode": 4551,
    "name": "Moffat Beach",
    "value": "Moffat Beach"
  },
  {
    "postcode": 4551,
    "name": "Pelican Waters",
    "value": "Pelican Waters"
  },
  {
    "postcode": 4552,
    "name": "Bald Knob",
    "value": "Bald Knob"
  },
  {
    "postcode": 4552,
    "name": "Balmoral Ridge",
    "value": "Balmoral Ridge"
  },
  {
    "postcode": 4552,
    "name": "Maleny",
    "value": "Maleny"
  },
  {
    "postcode": 4553,
    "name": "Diamond Valley",
    "value": "Diamond Valley"
  },
  {
    "postcode": 4553,
    "name": "Glenview",
    "value": "Glenview"
  },
  {
    "postcode": 4553,
    "name": "Mooloolah",
    "value": "Mooloolah"
  },
  {
    "postcode": 4553,
    "name": "Mooloolah Valley",
    "value": "Mooloolah Valley"
  },
  {
    "postcode": 4553,
    "name": "Palmview",
    "value": "Palmview"
  },
  {
    "postcode": 4554,
    "name": "Eudlo",
    "value": "Eudlo"
  },
  {
    "postcode": 4554,
    "name": "Ilkley",
    "value": "Ilkley"
  },
  {
    "postcode": 4555,
    "name": "Chevallum",
    "value": "Chevallum"
  },
  {
    "postcode": 4555,
    "name": "Hunchy",
    "value": "Hunchy"
  },
  {
    "postcode": 4555,
    "name": "Landers Shoot",
    "value": "Landers Shoot"
  },
  {
    "postcode": 4555,
    "name": "Palmwoods",
    "value": "Palmwoods"
  },
  {
    "postcode": 4556,
    "name": "Buderim",
    "value": "Buderim"
  },
  {
    "postcode": 4556,
    "name": "Forest Glen",
    "value": "Forest Glen"
  },
  {
    "postcode": 4556,
    "name": "Kunda Park",
    "value": "Kunda Park"
  },
  {
    "postcode": 4556,
    "name": "Mons",
    "value": "Mons"
  },
  {
    "postcode": 4556,
    "name": "Sippy Downs",
    "value": "Sippy Downs"
  },
  {
    "postcode": 4556,
    "name": "Tanawha",
    "value": "Tanawha"
  },
  {
    "postcode": 4557,
    "name": "Mooloolaba",
    "value": "Mooloolaba"
  },
  {
    "postcode": 4557,
    "name": "Mountain Creek",
    "value": "Mountain Creek"
  },
  {
    "postcode": 4558,
    "name": "Cotton Tree",
    "value": "Cotton Tree"
  },
  {
    "postcode": 4558,
    "name": "Kuluin",
    "value": "Kuluin"
  },
  {
    "postcode": 4558,
    "name": "Maroochydore",
    "value": "Maroochydore"
  },
  {
    "postcode": 4559,
    "name": "Diddillibah",
    "value": "Diddillibah"
  },
  {
    "postcode": 4559,
    "name": "Kiels Mountain",
    "value": "Kiels Mountain"
  },
  {
    "postcode": 4559,
    "name": "West Woombye",
    "value": "West Woombye"
  },
  {
    "postcode": 4559,
    "name": "Woombye",
    "value": "Woombye"
  },
  {
    "postcode": 4560,
    "name": "Bli Bli",
    "value": "Bli Bli"
  },
  {
    "postcode": 4560,
    "name": "Burnside",
    "value": "Burnside"
  },
  {
    "postcode": 4560,
    "name": "Coes Creek",
    "value": "Coes Creek"
  },
  {
    "postcode": 4560,
    "name": "Cooloolabin",
    "value": "Cooloolabin"
  },
  {
    "postcode": 4560,
    "name": "Dulong",
    "value": "Dulong"
  },
  {
    "postcode": 4560,
    "name": "Flaxton",
    "value": "Flaxton"
  },
  {
    "postcode": 4560,
    "name": "Highworth",
    "value": "Highworth"
  },
  {
    "postcode": 4560,
    "name": "Image Flat",
    "value": "Image Flat"
  },
  {
    "postcode": 4560,
    "name": "Kiamba",
    "value": "Kiamba"
  },
  {
    "postcode": 4560,
    "name": "Kulangoor",
    "value": "Kulangoor"
  },
  {
    "postcode": 4560,
    "name": "Kureelpa",
    "value": "Kureelpa"
  },
  {
    "postcode": 4560,
    "name": "Mapleton",
    "value": "Mapleton"
  },
  {
    "postcode": 4560,
    "name": "Montville",
    "value": "Montville"
  },
  {
    "postcode": 4560,
    "name": "Nambour",
    "value": "Nambour"
  },
  {
    "postcode": 4560,
    "name": "Parklands",
    "value": "Parklands"
  },
  {
    "postcode": 4560,
    "name": "Perwillowen",
    "value": "Perwillowen"
  },
  {
    "postcode": 4560,
    "name": "Rosemount",
    "value": "Rosemount"
  },
  {
    "postcode": 4560,
    "name": "Towen Mountain",
    "value": "Towen Mountain"
  },
  {
    "postcode": 4561,
    "name": "Bridges",
    "value": "Bridges"
  },
  {
    "postcode": 4561,
    "name": "Ninderry",
    "value": "Ninderry"
  },
  {
    "postcode": 4561,
    "name": "North Arm",
    "value": "North Arm"
  },
  {
    "postcode": 4561,
    "name": "Valdora",
    "value": "Valdora"
  },
  {
    "postcode": 4561,
    "name": "Yandina",
    "value": "Yandina"
  },
  {
    "postcode": 4562,
    "name": "Doonan",
    "value": "Doonan"
  },
  {
    "postcode": 4562,
    "name": "Eerwah Vale",
    "value": "Eerwah Vale"
  },
  {
    "postcode": 4562,
    "name": "Eumundi",
    "value": "Eumundi"
  },
  {
    "postcode": 4562,
    "name": "Verrierdale",
    "value": "Verrierdale"
  },
  {
    "postcode": 4562,
    "name": "Weyba Downs",
    "value": "Weyba Downs"
  },
  {
    "postcode": 4563,
    "name": "Cooroy",
    "value": "Cooroy"
  },
  {
    "postcode": 4563,
    "name": "Tinbeerwah",
    "value": "Tinbeerwah"
  },
  {
    "postcode": 4564,
    "name": "Marcoola",
    "value": "Marcoola"
  },
  {
    "postcode": 4564,
    "name": "Mudjimba",
    "value": "Mudjimba"
  },
  {
    "postcode": 4564,
    "name": "Pacific Paradise",
    "value": "Pacific Paradise"
  },
  {
    "postcode": 4564,
    "name": "Twin Waters",
    "value": "Twin Waters"
  },
  {
    "postcode": 4565,
    "name": "Tewantin",
    "value": "Tewantin"
  },
  {
    "postcode": 4566,
    "name": "Noosaville",
    "value": "Noosaville"
  },
  {
    "postcode": 4567,
    "name": "Castaways Beach",
    "value": "Castaways Beach"
  },
  {
    "postcode": 4567,
    "name": "Noosa Heads",
    "value": "Noosa Heads"
  },
  {
    "postcode": 4567,
    "name": "Sunrise Beach",
    "value": "Sunrise Beach"
  },
  {
    "postcode": 4567,
    "name": "Sunshine Beach",
    "value": "Sunshine Beach"
  },
  {
    "postcode": 4572,
    "name": "Alexandra Headland",
    "value": "Alexandra Headland"
  },
  {
    "postcode": 4573,
    "name": "Coolum Beach",
    "value": "Coolum Beach"
  },
  {
    "postcode": 4573,
    "name": "Marcus Beach",
    "value": "Marcus Beach"
  },
  {
    "postcode": 4573,
    "name": "Mount Coolum",
    "value": "Mount Coolum"
  },
  {
    "postcode": 4573,
    "name": "Peregian Beach",
    "value": "Peregian Beach"
  },
  {
    "postcode": 4573,
    "name": "Peregian Springs",
    "value": "Peregian Springs"
  },
  {
    "postcode": 4573,
    "name": "Point Arkwright",
    "value": "Point Arkwright"
  },
  {
    "postcode": 4573,
    "name": "Yaroomba",
    "value": "Yaroomba"
  },
  {
    "postcode": 4575,
    "name": "Birtinya",
    "value": "Birtinya"
  },
  {
    "postcode": 4575,
    "name": "Bokarina",
    "value": "Bokarina"
  },
  {
    "postcode": 4575,
    "name": "Buddina",
    "value": "Buddina"
  },
  {
    "postcode": 4575,
    "name": "Kawana",
    "value": "Kawana"
  },
  {
    "postcode": 4575,
    "name": "Minyama",
    "value": "Minyama"
  },
  {
    "postcode": 4575,
    "name": "Parrearra",
    "value": "Parrearra"
  },
  {
    "postcode": 4575,
    "name": "Warana",
    "value": "Warana"
  },
  {
    "postcode": 4575,
    "name": "Wurtulla",
    "value": "Wurtulla"
  },
  {
    "postcode": 2485,
    "name": "Tweed Heads",
    "value": "Tweed Heads"
  },
  {
    "postcode": 2486,
    "name": "Bilambil Heights",
    "value": "Bilambil Heights"
  },
  {
    "postcode": 2486,
    "name": "Benora Point",
    "value": "Benora Point"
  },
  {
    "postcode": 2486,
    "name": "Cobaki",
    "value": "Cobaki"
  },
  {
    "postcode": 2486,
    "name": "Piggabeen",
    "value": "Piggabeen"
  },
  {
    "postcode": 2486,
    "name": "Tweed heads South",
    "value": "Tweed heads South"
  }
];

export const suburbOptions = suburbs.map(suburb => ({
  value: `${suburb.postcode} - ${suburb.name}`,
  label: `${suburb.postcode} - ${suburb.name}`,
}));

export default suburbs;
