import React from 'react';
import { Input } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    formControl: {
      padding: '10px 20px',
      color: '#181A38',
      fontSize: '1.2rem',
      lineHeight: 1.5,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      backgroundColor: '#fff',
      border: 0,
      boxShadow: 'none',
      width: '100%',
      borderRadius: 0,
      [theme.breakpoints.up(400)]: {
        padding: '5px 10px',
        fontSize: `${theme.typography.enlargePercent(1.2)}rem`,
      },
      '&::placeholder': {
        fontSize: '1.2rem',
        color: '#e9e9e9',
        fontWeight: '600',
        [theme.breakpoints.up(400)]: {
          fontSize: `${theme.typography.enlargePercent(1.5)}rem`,
        }
      },
    },
  });

const InputWrapped = ({ classes, name, ...rest }) => (
  <Input
    className={classes.formControl}
    name={name}
    {...rest}
  />
);

export default withStyles(styles, { withTheme: true })(InputWrapped);
