import SiteLink from '../SiteLink';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';

import styles from './MainMenuItem.css';

const MainMenuItem = ({ classes, linkName, href }) => (
  <SiteLink
      className={classes.link}
      to={href}
      activeClassName={classes.active}
    >
    <ListItem
      className={classes.menuItem}
      component="li"
      button
    >
      {linkName}  
    </ListItem>
  </SiteLink>
);

export default withStyles(styles, { withTheme: true })(MainMenuItem);
