const submitForm = (subject, data) => {
  if (!process.env.GATSBY_SUBMIT_FORM_URL || !process.env.GATSBY_SUBMIT_FORM_EMAIL) {
    return Promise.reject(new Error('Missing config'))
  }
  return fetch(process.env.GATSBY_SUBMIT_FORM_URL, {
    method: 'POST',
    body: JSON.stringify({ subject, data, destination: process.env.GATSBY_SUBMIT_FORM_EMAIL }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) throw new Error('Request failed');
    })
}

export default submitForm;
