
import React, { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { TVT_FB_URL, TVT_INSTA_URL } from '../../constants';

import {
  Grid,
  Typography,
  Divider,
  Button,
  Link as MuiLink,
  Hidden,
} from '@material-ui/core';

import submitForm from '../../helpers/submitForm';

import Image from '../Image';

import { pxToRem } from '../../theme';

import FB from '../../images/fb.svg';
import Insta from '../../images/insta.svg';

import Input from '../Quote/Input';
import { UiButton } from '../CTAButton/CTAButton';
import ResultSnackbar from '../ResultSnackbar/ResultSnackbar';

const styles = theme => {
  const footerSections = {
    padding: 40,
    paddingBottom: 0,
    lineHeight: '1rem',
  };

  return createStyles({
    title: {
      fontSize: pxToRem(20),
      display: 'flex',
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(24),
      },
    },
    openningHours: {
      fontSize: pxToRem(16),
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(20),
      },
      [theme.breakpoints.up(960)]: {
        fontSize: pxToRem(16),
        fontWeight: 400,
      },
    },
    footerWrap: {
      backgroundColor: theme.palette.primary.main,
    },
    footer: {
      color: theme.palette.common.white,
      display: 'flex',
      maxWidth: 1440,
      margin: '0 auto',
      flexGrow: 1,
      padding: '0 0 20px 0',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
      },
    },
    footerContentWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
      [theme.breakpoints.down(960)]: {
        flexDirection: 'row',
      },
      // [theme.breakpoints.down(640)]: {
      //   flexDirection: 'column',
      // },
    },
    footerSections: {
      padding: 25,
      paddingBottom: 0,
      lineHeight: '1rem',
      [theme.breakpoints.up(400)]: {
        padding: 40,
      },
    },
    phone: {
      fontSize: '1.2rem',
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'none',
      fontWeight: 600,
      lineHeight: 1.5,
      color: '#fff',
      [theme.breakpoints.up(400)]: {
        fontSize: `${theme.typography.enlargePercent(1.2, 60)}rem`,
      },
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    socialSection: {
      ...footerSections,
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: 'center',
      [theme.breakpoints.up(960)]: {
        padding: '50px 0px 0px 0px',
        order: 2,
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    socialChannel: {
      ...footerSections,
      paddingTop: 0,
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up(960)]: {
        padding: 0,
        justifyContent: 'start',
      },
    },
    socialSectionTitleWrap: {
      display: 'flex',
    },
    socialSectionTitle: {
      display: 'none',
      fontSize: pxToRem(20),
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(24),
      },
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    contactSection: {
      ...footerSections,
      [theme.breakpoints.up(600)]: {
        alignItems: 'flex-end',
        textAlign: 'right'
      },
      [theme.breakpoints.up(960)]: {
        padding: '50px 0px 0px 70px',
        order: 0,
        alignItems: 'flex-start',
        textAlign: 'left'
      },
    },
    toTop: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      padding: 20,
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    stayConnected: {
      ...footerSections,
      flexDirection: 'column',
      [theme.breakpoints.up(960)]: {
        padding: '50px 20px 0px',
        order: 1,
      },
    },
    stayConnectedTextMobile: {
      display: 'flex',
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    stayConnectedTextDesktop: {
      display: 'none',
      [theme.breakpoints.up(960)]: {
        display: 'flex',
   
      },
    },
    stayConnectedInputBox: {
      width: '100%',
      maxWidth: 300,
      marginBottom: 15,
    },
    socialLink: {
      width: 40,
      height: 40,
      marginBottom: -2,
      marginRight: '10px', //
      [theme.breakpoints.up(400)]: {
        width: 60,
        height: 60,
        marginBottom: -3,
      },
    },
    socialLinkImg: {
      width: '100%',
      maxWidth: 50,
    },
    copyright: {
      ...footerSections,
    },
    telephone: {
      display: 'none',
      padding: '20px 0px 0px 0px',
      fontWeight: 500,
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    visitUs: {
      display: 'none',
      padding: '20px 0px 5px 0px',
      fontWeight: 500,
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    companyAddress: {
      display: 'none',
      fontSize: pxToRem(16),
      fontWeight: 400,
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    emailUs: {
      display: 'none',
      padding: '20px 0px 5px 0px',
      fontWeight: 500,
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    emailAddress: {
      display: 'none',
      fontSize: pxToRem(16),
      fontWeight: 400,
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    linkWrap: {
      textDecoration: 'none',
      color: 'white',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    lsaImageWrap: {
      padding: '20px 0',
      minHeight: 150,
      [theme.breakpoints.up(960)]: {
        paddingRight: 10,
      },
      [theme.breakpoints.up(1260)]: {
        paddingRight: 100,
      },
    },
    lsaImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    paddedFooter: {
      // paddingBottom: '160px'
    },
    socialIconsWrap: {
      display: 'flex'
    }
  });
};

const phoneNumber = '1800 686 711';

const Footer = ({ classes, footerClass }) => {
  const [formData, setFormData] = useState({});
  const [snack, setSnack] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const getInputProps = (placeholder, name) => ({
    placeholder: placeholder,
    value: formData[name] || '',
    onChange: event => {
      const { value } = event.target;
      setFormData(prev => ({ ...prev, [name]: value }));
    },
    required: true,
    disabled: isLoading,
  });

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    submitForm('Subscription to TVT Mail', formData)
      .then(() => {
        setSnack({
          variant: 'success',
          message: 'Successfully subscribed to TVT Mail.',
        });
        setFormData({});
      })
      .catch(() => {
        setSnack({
          variant: 'error',
          message: 'Error subscribing to TVT Mail.',
        });
      })
      .then(() => setLoading(false));
  };

  return (
    <Grid container item direction="column" className={classNames({[classes.footerWrap]: true}, {[classes.paddedFooter]: footerClass} )} >
      <Grid
        container
        item
        direction="column"
        className={classes.footer}
        component="footer"
      >
        <Grid container item>
          <Grid container item className={classes.toTop}>
            <Button href="#mainContent" color="secondary">
              To top
            </Button>
          </Grid>
          <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
          <Grid container className={classes.footerContentWrap}>
            <Grid
              justify="space-between"
              className={classes.socialSection}
              container
              md={4}
              item
            >
              <Grid container item className={classes.socialSectionTitleWrap}>
                <Typography
                  className={classes.socialSectionTitle}
                  variant="h2"
                  color="secondary"
                  style={{ marginBottom: 25, fontWeight: 600 }}
                >
                  Connect with us
                </Typography>
              </Grid>

              <Grid
                container
                item
                direction="row"
                className={classes.socialChannel}
              >
                <div
                  className={classes.socialIconsWrap}
                >
                <MuiLink
                  className={classes.socialLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={TVT_FB_URL}
                >
                  <img className={classes.socialLinkImg} alt="" src={FB} />
                </MuiLink>
                <MuiLink
                  className={classes.socialLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={TVT_INSTA_URL}
                >
                  <img className={classes.socialLinkImg} alt="" src={Insta} />
                </MuiLink>
                </div>
                <a
                  className={classes.phone}
                  href="tel:1800686711"
                  rel="noopener noreferrer"
                >
                  {phoneNumber}
                </a>
                <Grid item container className={classes.lsaImageWrap}>
                  <Image
                    relativePath="LSA_Logo_Landscape_Tagline_Rev.png"
                    className={classes.lsaImage}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              alignItems="flex-start"
              className={classes.stayConnected}
              container
              item
              md={4}
              sm={6} //
              direction="row"
            >
              <Typography
                className={classes.title}
                variant="h2"
                color="secondary"
                // noWrap={true}
                style={{ marginBottom: 25,fontWeight: 600 }}
              >
                <span className={classes.stayConnectedTextMobile}>
                  Stay Connected
                </span>
                <span className={classes.stayConnectedTextDesktop}>
                  Subscribe to TVT Mail
                </span>
              </Typography>

              <form onSubmit={handleSubmit}>
                <Hidden smDown>
                  <Input
                    {...getInputProps('Full Name', 'fullName')}
                    classes={{
                      formControl: classes.stayConnectedInputBox,
                    }}
                  />
                </Hidden>

                <Input
                  {...getInputProps('Email address', 'email')}
                  classes={{
                    formControl: classes.stayConnectedInputBox,
                  }}
                />
                <UiButton
                  type="submit"
                  style={{
                    fontSize: pxToRem(20),
                    fontWeight: 500,
                    maxWidth: 200,
                    width: '50%',
                    paddingBottom: '5px',
                  }}
                  inverted
                  disabled={isLoading}
                >
                  Subscribe
                </UiButton>
                {snack && (
                  <ResultSnackbar {...snack} onClose={() => setSnack(null)} />
                )}
              </form>
            </Grid>

            <Grid
              alignItems="flex-start"
              className={classes.contactSection}
              container
              item
              md={4}
              sm={6} //
              direction="column"
            >
              <Grid item>
                <Typography
                  className={classes.title}
                  variant="h2"
                  color="secondary"
                  style={{ fontWeight: 600 }}
                  >
                  Contact us
                </Typography>
              </Grid>

              <Grid item>
                <a
                  href="tel:1800686711"
                  className={classes.linkWrap}
                >
                <Typography
                  className={classes.telephone}
                  variant="body1"
                  color="secondary"
                >
                  {phoneNumber}
                </Typography>
                </a>
              </Grid>

              <Grid item style={{ paddingTop: 20 }}>
                <Typography
                  className={classes.openningHours}
                  variant="body1"
                  color="secondary"
                >
                  7am – 5pm Mon to Thu
                  <br />
                  7am – 4.30pm Fri
                  <br />
                  8am – 12.00pm Sat
                  <br />
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  className={classes.visitUs}
                  variant="body1"
                  color="secondary"
                >
                  Visit us
                </Typography>
              </Grid>

              <Grid item>
                <a href="https://www.google.com/maps?q=410+Powell+Road,+Wamuran+QLD+4512&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiRvK2ys-jrAhXMk4sKHRa4AKkQ_AUoAXoECAwQAw"
                   className={classes.linkWrap} 
                   target="_blank"
                   rel="noopener noreferrer"
                >
                <Typography
                  className={classes.companyAddress}
                  variant="body1"
                  color="secondary"
                >
                  410 Powell Road,
                  <br />
                  Wamuran QLD 4512
                </Typography>
                </a>
              </Grid>

              <Grid item>
                <Typography
                  className={classes.emailUs}
                  variant="body1"
                  color="secondary"
                >
                  Email us
                </Typography>
              </Grid>

              <Grid item>
                <a
                  href="mailto:sales@twinviewturf.com.au"
                  className={classes.linkWrap}
                >
                <Typography
                  className={classes.emailAddress}
                  variant="body1"
                  color="secondary"
                >
                  sales@twinviewturf.com.au
                </Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ padding: '40px' }}
          className={classes.copyright}
          container
          justify="center"
          item
        >
          <Typography
            variant="body1"
            color="secondary"
            style={{ textAlign: 'center', padding: 20, fontWeight: 300 }}
          >
            Twin View Turf Pty Ltd &copy; {new Date().getFullYear()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(Footer);

