import React from 'react';
import { Dialog, Slide } from '@material-ui/core';
import Quote from './Quote';

const Transition = (props) => <Slide direction="up" {...props} />;

const TransitionalQuote = (props) => (
  <Dialog fullScreen open={props.isQuoteWizardOpen} TransitionComponent={Transition}>
    <Quote closeFn={props.toggleQuoteWizard} defaultTurf={props.defaultTurf} />
  </Dialog>
);

export default TransitionalQuote;
