import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Icon, Drawer as MuiDrawer } from '@material-ui/core';
// import classNames from 'classnames';
import isMobile from 'is-mobile';

import { Phone } from '@material-ui/icons';

import MainMenuList from '../MainMenu/MainMenuList';
import MainMenuDrawerHead from '../MainMenu/MainMenuDrawerHead';

import {
  drawerWidthSmall,
  drawerWidthMedium,
  drawerWidthLarge,
} from '../layout';

const styles = theme => {
  return createStyles({
    drawer: {
      width: drawerWidthSmall,
      flexShrink: 0,
      backgroundColor: theme.palette.primary.main,

      [theme.breakpoints.up(350)]: {
        width: drawerWidthMedium,
      },

      [theme.breakpoints.up(400)]: {
        width: drawerWidthLarge,
      }
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      width: drawerWidthSmall,

      [theme.breakpoints.up(350)]: {
        width: drawerWidthMedium,
      },

      [theme.breakpoints.up(400)]: {
        width: drawerWidthLarge,
      }
    },
    drawerContent: {
      backgroundColor: theme.palette.primary.main,
      flexWrap: 'nowrap'
    },
    phoneWrap: {
      alignSelf: 'flex-end',
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    phone: {
      display: 'flex',
      padding: 15,
      color: theme.palette.common.white,
    },
    phoneIcon: {
      fontSize: '2.5rem',
      [theme.breakpoints.up(450)]: {
        fontSize: '3.5rem',
      }
    },
  });
};

class Drawer extends Component {
  render() {
    const { classes, isMenuOpen, closeMenu, toggleQuoteComp } = this.props;
    const isMobilePhone = isMobile();

     return (
      <MuiDrawer
        anchor="right"
        variant="persistent"
        open={isMenuOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
      >
        <Grid
          container
          direction="column"
          tabIndex={0}
          role="button"
          onClick={() => closeMenu(false)}
          className={classes.drawerContent}
        >
          <MainMenuDrawerHead toggleQuoteComp={toggleQuoteComp} />
          <MainMenuList />
          
          {isMobilePhone && <Grid
              item
              container
              alignContent="center"
              justify="center"
              className={classes.phoneWrap}
            >
              <a className={classes.phone} href="tel:1800686711" rel="noopener noreferrer">
                <Icon className={classes.phoneIcon} component={Phone} />
              </a>
            </Grid>
          }
        </Grid>
      </MuiDrawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Drawer);
