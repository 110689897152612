import { createStyles } from '@material-ui/core/styles';

export default theme => {
  return createStyles({
    menuHead: {
      backgroundColor: theme.palette.common.white,
    },
    burgerMenuWrap: {
      justifyContent: 'flex-end',
   
    },
    burgerMenu: {
      display: 'flex',
      padding: '10px 30px 10px 20px',
    },
    phoneNumberWrap: {
      justifyContent: 'flex-start',

      [theme.breakpoints.up('md')]: {
        paddingLeft: 30,
       
      },
    },
    phone: {
      color: theme.palette.common.white,
      display: 'inline-block',
      padding: '10px 35px 10px 20px',
      [theme.breakpoints.up(420)]: {
        maxWidth: 150,
      },
    },
    phoneDesktop: {
      textDecoration: 'none',
      display: 'flex',
    },
    linkHead: {
      color: theme.palette.primary.main,
  
      width: 120,
      border: '1px solid transparent', //
      [theme.breakpoints.up(360)]: {
        width: 150,
      },
      [theme.breakpoints.up(440)]: {
        width: 170,
      },
    },

    logoImg: {
      
    },
    burgerMenuText: {
      fontWeight: 600,
      paddingRight: '20px',
      fontSize: '1.5rem'
    },

  });
};
