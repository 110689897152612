import { createStyles } from '@material-ui/core/styles';

import { defaultTransition } from '../../theme';

const styles = theme => {
  const getLinkStyling = theme => ({
    textDecoration: 'none',
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 350,
    lineHeight: '1.40',
    fontSize: '1rem',
    verticalAlign: 'middle',
    [theme.breakpoints.up(400)]: {
      fontSize: `${theme.typography.enlargePercent(1)}rem`,
    }
  });

  return createStyles({
    menuItem: {
      padding: '15px 20px',
      borderBottom: `1px solid rgba(255, 255, 255, 0.1)`,
      cursor: 'pointer',
      marginLeft: 0,
      ...defaultTransition(theme),
      '&:hover': {
        marginLeft: 1,
      },
    },
    link: getLinkStyling(theme),
    active: {
      fontWeight: '600',
    },
  });
};

export default styles;
