import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = theme => createStyles({
  success: {
    backgroundColor: '#1BB25F',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  content: {
    fontSize: '1rem',
  },
});

const ResultSnackbar = ({ classes, className, message, onClose, variant, ...other }) => (
  <Snackbar open>
    <SnackbarContent
      className={classNames(classes[variant], classes.content, className)}
      message={message}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  </Snackbar>
);

export default withStyles(styles, { withTheme: true })(ResultSnackbar);
