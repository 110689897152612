import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button, Icon } from '@material-ui/core';
import { CloseSharp } from '@material-ui/icons';

const styles = theme =>
  createStyles({
    closeButtonWrap: {
      position: 'absolute',
      top: '0',
      right: '0',
    },
    closeBtn: {
      textDecoration: 'none',
      color: '#fff',
      padding: 10,
      [theme.breakpoints.up(400)]: {
        padding: 20,
      }
    },
  });

const CloseButton = ({ classes, closeFn, iconProps, ...rest }) => (
  <Grid item className={classes.closeButtonWrap}>
    <Button
      color="primary"
      className={classes.closeBtn}
      onClick={(e) => {
         e.preventDefault();
         e.stopPropagation();
        closeFn();
      }}
      {...rest}
    >
      <Icon {...iconProps} fontSize="large" component={CloseSharp} />
    </Button>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(CloseButton);
