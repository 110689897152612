import React from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = theme =>
  createStyles({
    root: {
      padding: '10px 25px 14px',
    },
    label: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.2rem',
      textAlign: 'left',
      lineHeight: 1.5,
      color: '#FFF',
    },
  });

class CheckboxGroup extends React.Component {
  onSelect = (data, isChecked) => {
    this.props.onChange({
      [this.props.options[0].value]: !isChecked,
      ...data,
    });
  };

  render() {
    const { options, classes } = this.props;

    return (
      <Grid item container direction="column" className={classes.root}>
        {options.map(o => (
          <FormControlLabel
            key={o.value}
            control={
              <Checkbox
                disableRipple
                checked={o.checked}
                value={o.value}
                icon={
                  <CheckBoxOutlineBlankIcon
                    style={{ fill: '#fff', color: '#fff' }}
                  />
                }
                checkedIcon={
                  <CheckBoxIcon style={{ fill: '#fff' }} />
                }
                onChange={(event) => this.onSelect({
                  [o.value]: !o.checked
                }, !o.checked)}
              />
            }
            label={o.text}
            classes={{
              label: classes.label,
            }}
          />
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CheckboxGroup);
