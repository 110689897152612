import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, AppBar } from '@material-ui/core';
import classNames from 'classnames';
import { defaultTransition } from '../../theme';

import MenuHeader from './MenuHeader';

import {
  drawerWidthSmall,
  drawerWidthMedium,
  drawerWidthLarge,
} from '../layout';

const styles = theme => {
  const trans = defaultTransition(theme);

  return createStyles({
    appBar: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      ...trans,
    },
    menuWrap: {
      position: 'relative',
      height: '12vh',
      minHeight: 68,
      ...trans,
      [theme.breakpoints.up(350)]: {
        minHeight: 80,
      }
    },
    menuWrapShift: {
      left: -drawerWidthSmall,
      ...trans,

      [theme.breakpoints.up(350)]: {
        left: -drawerWidthMedium,
        ...trans,
      },

      [theme.breakpoints.up(400)]: {
        left: -drawerWidthLarge,
        ...trans,
      },

      [theme.breakpoints.up(800)]: {
        left: 0,
        width: `calc(100% - ${drawerWidthLarge}px)`,
        ...trans,
      },
    },
    phoneWrap: {
      alignSelf: 'flex-end',
      textAlign: 'center',
      height: '12%',
      padding: 5,
      backgroundColor: theme.palette.primary.main,
    },
    phone: {
      display: 'flex',
      color: theme.palette.common.white,
    },
    phoneIcon: {
      fontSize: '3.5rem',
    },
  });
};

// this is a comment
class MainMenu extends Component {
  render() {
    const { classes, closeMenu, openMenu, isMenuOpen, ...rest } = this.props;
     return (
      <AppBar
        {...rest}
        position="fixed"
        className={classes.appBar}
      >
        <Grid container item
          className={classNames(classes.menuWrap, {
            [classes.menuWrapShift]: isMenuOpen,
          })}
        >
          <MenuHeader
            isMenuOpen={isMenuOpen}
            closeMenu={closeMenu}
            openMenu={openMenu}
          />
        </Grid>
      </AppBar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MainMenu);
