import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { withStyles, createStyles } from '@material-ui/core/styles';
// import { Grid, Typography } from '@material-ui/core';

// import { pxToRem } from '../theme';

const styles = theme =>
  createStyles({

  });

const SiteLink = ({ to, children, ...rest }) => (
  <AniLink
    fade
    // exit={{
    //   delay: 0.8,
    // }}
    entry={{
      delay: 0,
    }}
    duration={0.8}
    to={to}
    {...rest}
  >
    {children}
  </AniLink>
);

export default withStyles(styles, { withTheme: true })(SiteLink);
