import SiteLink from '../SiteLink';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuSharp, Phone, CloseSharp } from '@material-ui/icons';

import { Grid, Button, Icon, Typography, withWidth, Hidden } from '@material-ui/core';

// import Logo from '../Logo/Logo';
import Image from '../Image';
import {LogoImage} from '../../imageURLs';

import styles from './MenuHeader.css';

const MenuHeader = ({
  classes,
  isMenuOpen,
  closeMenu,
  openMenu,
}) => {
  return (
    <Grid
      container
      item
      direction="row"
      wrap="nowrap"
      className={classes.menuHead}
      alignItems="center"
      justify="space-between"
    >

      <Grid
        item
        container
        className={classes.phoneNumberWrap}
      >
        <Hidden mdUp>
          <a className={classes.phone} href="tel:1800686711" rel="noopener noreferrer">
            <Icon color="primary" fontSize="large" component={Phone} />
          </a>
        </Hidden>

        <Hidden smDown>
          <a className={classes.phoneDesktop} href="tel:1800686711">
            <Icon
              color="primary"
              fontSize="large"
              component={Phone}
              style={{ marginRight: 10 }}
            />
            <Typography variant="h6" style={{ fontWeight: 600 }}>1800 686 711</Typography>
          </a>
        </Hidden>
      </Grid>

      <Grid item container
        justify="center"
        alignContent="center"
        className={classes.logoWrap}
      >
        <SiteLink className={classes.linkHead} to="/">
          {/* <Logo /> */}
          <Image
            relativePath={LogoImage.logo}
            className={classes.logoImg}
            imgStyle={{ width: '100%'}}
          />
        </SiteLink>
      </Grid>

      <Grid
        item
        container
        justify="center"
        className={classes.burgerMenuWrap}>
        <Button
          onClick={isMenuOpen ? closeMenu: openMenu}
          className={classes.burgerMenu}
        >
            <Hidden mdDown>
              <Typography
                className={classes.burgerMenuText}
              >
                Menu
              </Typography>
            </Hidden>
          <Icon
            color="primary"
            fontSize="large"
            component={isMenuOpen ? CloseSharp : MenuSharp}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(withWidth()(MenuHeader));
