export const TVT_GOOGLE_URL = 'https://www.google.com/maps?cid=6183394354589031017';
export const TVT_FB_URL = 'https://www.facebook.com/pages/Twin-View-Turf/442150172510212';
export const TVT_INSTA_URL = 'https://www.instagram.com/twinviewturf/';

const Answers = {
  Q1A: 'Thick & lush',
  Q1B: 'Fine & hardy',
  Q2A: 'A bit shady',
  Q2B: 'Mostly sunny',
  Q3A: 'I don\'t mind',
  Q3B: 'Less is more',
};

export const wizardConfig = {
  steps: [
    {
      question: 'What style of lawn do you want?',
      answers: [
        {
          text: Answers.Q1A,
          value: Answers.Q1A,
        },
        {
          text: Answers.Q1B,
          value: Answers.Q1B,
        },
      ],
    },
    {
      question: 'What best describes your lawn area?',
      answers: [
        {
          text: Answers.Q2A,
          value: Answers.Q2A,
        },
        {
          text: Answers.Q2B,
          value: Answers.Q2B,
        },
      ],
    },
    {
      question: 'How do you feel about mowing your lawn?',
      answers: [
        {
          text: Answers.Q3A,
          value: Answers.Q3A,
        },
        {
          text: Answers.Q3B,
          value: Answers.Q3B,
        },
      ],
    },
  ],
  results: [
    {
      choices: [Answers.Q1A, Answers.Q2B, Answers.Q3A],
      types: [
        {
          href: '/turf-sir-walter-dna-certified',
          name: 'Sir Walter',
        },
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
      ],
      resultCopy: `<strong>Sir Walter DNA Certified</strong> is a thick, lush lawn that looks great in full sun and partly shaded areas. It's a practical choice for growing families, people with dogs and for quality outdoor time on the weekends. It's the best low maintenance broad leaf buffalo in Australia – though if you're ready for a true no-fuss lawn, you'll love the six week mow schedule and soft, spongey feel of a <strong>Sir Grange</strong> zoysia lawn.`,
    },
    {
      choices: [Answers.Q1A, Answers.Q2B, Answers.Q3B],
      types: [
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
        {
          href: '/turf-sir-walter-dna-certified',
          name: 'Sir Walter',
        },
      ],
      resultCopy: `<strong>Sir Grange</strong> is the very definition of low maintenance when it comes to mowing. It can be left for up to six weeks without needing a mow and it will still look amazing. It's super fine leaf (the finest in Australia) creates a thick, dense lawn style that is super soft to walk on and highly resistant to weeds, pests and drought. <strong>Sir Walter DNA Certified</strong> also makes a great choice, so long as you're up for the weekly mow.`,
    },
    {
      choices: [Answers.Q1A, Answers.Q2A, Answers.Q3A],
      types: [
        {
          href: '/turf-sir-walter-dna-certified',
          name: 'Sir Walter',
        },
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
      ],
      resultCopy: `<strong>Sir Walter DNA Certified</strong> is a thick, lush lawn that looks great in full sun and partly shaded areas. It's a practical choice for growing families, people with dogs and for quality outdoor time on the weekends. It's the best low maintenance broad leaf buffalo in Australia – though if you're ready for a true no-fuss lawn, you'll love the six week mow schedule and soft, spongey feel of a <strong>Sir Grange</strong> zoysia lawn.`,
    },
    {
      choices: [Answers.Q1A, Answers.Q2A, Answers.Q3B],
      types: [
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
        {
          href: '/turf-sir-walter-dna-certified',
          name: 'Sir Walter',
        },
      ],
      resultCopy: `<strong>Sir Grange</strong> is a beautiful thick, shade tolerant lawn that can be left for up to six weeks before needing a mow. It's super fine leaf (the finest in Australia) and slow, dense growth habit is super soft to walk on and highly resistant to weeds, pests and drought. <strong>Sir Walter DNA Certified</strong> also makes a great shade tolerant choice, so long as you're up for the weekly mow.`,
    },
    {
      choices: [Answers.Q1B, Answers.Q2A, Answers.Q3A],
      types: [
        {
          href: '/turf-tiftuf',
          name: 'TifTuf',
        },
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
      ],
      resultCopy: `<strong>TifTuf</strong> is a compact, fine leaf lawn that's extremely hardy in full sun or part shade, so long as it gets atleast 2-3 hours of direct sun per day. A weekly mow, occasional water and bi-annual fertilise and TifTuf will hold it's thick, deep green colour even through winter. <strong>Sir Grange</strong> is an even better choice, with the major perk being a mowing cycle of (up to) every six weeks.`,
    },
    {
      choices: [Answers.Q1B, Answers.Q2B, Answers.Q3B],
      types: [
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
        {
          href: '/turf-tiftuf',
          name: 'TifTuf',
        },
      ],
      resultCopy: `<strong>Sir Grange</strong> is a beautiful thick, full sun and part shade tolerant lawn that can be left for up to six weeks before needing a mow. It's super fine leaf (the finest in Australia) and slow, dense growth habit is super soft to walk on and highly resistant to weeds, pests and drought. <strong>TifTuf</strong> also makes an excellent choice with similar qualities, so long as you're up for the weekly mow.`,
    },
    {
      choices: [Answers.Q1B, Answers.Q2B, Answers.Q3A],
      types: [
        {
          href: '/turf-tiftuf',
          name: 'TifTuf',
        },
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
      ],
      resultCopy: `<strong>TifTuf</strong> is a compact, fine leaf lawn that's extremely hardy in full sun or part shade, so long as it gets atleast 2-3 hours of direct sun per day. A weekly mow, occasional water and bi-annual fertilise and TifTuf will hold it's thick, deep green colour even through winter. <strong>Sir Grange</strong> is an even better choice, with the major perk being a mowing cycle of (up to) every six weeks.`,
    },
    {
      choices: [Answers.Q1B, Answers.Q2A, Answers.Q3B],
      types: [
        {
          href: '/turf-sir-grange',
          name: 'Sir Grange',
        },
        {
          href: '/turf-tiftuf',
          name: 'TifTuf',
        },
      ],
      resultCopy: `<strong>Sir Grange</strong> is a beautiful thick, shade tolerant lawn that can be left for up to six weeks before needing a mow. It's super fine leaf (the finest in Australia) and slow, dense growth habit is super soft to walk on and highly resistant to weeds, pests and drought. <strong>TifTuf</strong> also makes an excellent choice with similar qualities, so long as you're up for the weekly mow.`,
    },
  ],
};
