import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import SiteLink from '../SiteLink';

import { pxToRem } from '../../theme';

const styles = theme =>
  createStyles({
    ctaButton: {
      display: 'inline-block',
      padding: '10px 15px 12px',
      margin: 10,
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '1.2rem',
      borderRadius: 10,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'none',
      cursor: 'pointer',
      transition: '0.4s linear',//
     
      [theme.breakpoints.up(450)]: {
        padding: '12px 25px 15px',
        fontSize: `${theme.typography.enlargePercent(1.2)}rem`,
      },
    },
    ctaBtnDefault: {
      backgroundColor: '#1BB25F',
      border: '1px solid #1BB25F',//
      '&:hover': {
        backgroundColor: 'white',
        color: '#1BB25F',
        border: '1px solid #1BB25F'
      },
    },

    ctaBtnSmall: {
      margin: 0,
      fontSize: '1rem',
      padding: '8px 12px 10px',
    },

    ctaBtnSecondary: {
      border: '1px solid white',
      backgroundColor: 'transparent',
      color: 'white'
    },

    ctaBtnTertiary: {
      margin: 0,
      fontSize: '1rem',
      padding: '10px 12px 12px',
      [theme.breakpoints.up(450)]: {
        fontSize: '1.2rem',
        padding: '12px 24px 14px',
      },

    },

    ctaBtnDefaultInverted: {
      border: '1px solid #181A38',//
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: '1px solid #181A38'
      },
    },
    uiButton: {
      backgroundColor: '#1BB25F',
      textTransform: 'none',
      color: '#fff',
      borderRadius: 10,
      fontWeight: 300,
      fontSize: pxToRem(16),
      textAlign: 'center',
      transition: '0.4s linear',//
      
      '&:hover': {
        backgroundColor: '#1BB25F',
      },
      [theme.breakpoints.up(400)]: {
        borderRadius: 12,
        fontSize: pxToRem(20),
      },
    },
    uiButtonPrimary: {
      fontWeight: 500,
      backgroundColor: theme.palette.primary.main,
      border: '1px solid #181A38',//
      paddingBottom: '12px',
      '&:hover': {
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: 'white',
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.up(400)]: {
        borderRadius: 15,
        fontSize: pxToRem(27),
      },
    },

    uiButtonSecondary: {
      fontWeight: 500,
      backgroundColor: 'transparent',
      color: 'white',
      border: '1px solid white',
      '&:hover': {
        // backgroundColor: 'white',
        // color: theme.palette.green.main,
      },
      [theme.breakpoints.up(400)]: {
        borderRadius: 15,
        fontSize: pxToRem(27),
      },
    },

    uiButtonWide: {
      display: 'flex',
      width: '100%',
      padding: 15,
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      // border: 0,
      fontWeight: 500,
      fontSize: '1.2rem',
      lineHeight: 1,
      textDecoration: 'none',
      margin: '20px 12px 0 0',
      color: theme.palette.common.white,
      borderRadius: 10,
      border: '1px solid #181A38',
      transition: '0.4s linear',
      '&:hover': {
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: 'white',
        color: '#181A38'
      },
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(20),
        lineHeight: pxToRem(20),
      },
    },
    inverted: {
      backgroundColor: '#1BB25F',
      border: '1px solid #1BB25F', //
      '&:hover': {
        // backgroundColor: '#1BB25F',
        backgroundColor: 'white', //
        color: '#1BB25F', //
      },
    },
    uiButtonHollow: {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      border: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      borderRadius: 20,
      padding: '5px 20px 6px',
      '&:hover': {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
      },
    },
  });

const CTAButton = ({
  classes,
  ctaHref,
  ctaText,
  ctaOnClick = null,
  external = false,
  btnStyles = {},
  destinationState = null,
  inverted,
  secondary,
  tertiary,
  small,
  // invertedBtn,
  ...rest
}) => (
  <React.Fragment>
    {!external && (
      <SiteLink
        className={classNames(classes.ctaButton, classes.ctaBtnDefault, {
          [classes.ctaBtnDefaultInverted]: inverted
        },{[classes.ctaBtnSecondary]: secondary}, {[classes.ctaBtnTertiary]: tertiary}, {[classes.ctaBtnSmall]: small} )}
        style={{ ...btnStyles }}
        to={ctaHref}
        onClick={ctaOnClick}
        state={destinationState}
      >
        {ctaText}
      </SiteLink>
    )}
    {external && (
      <a
        className={classNames(classes.ctaButton, classes.ctaBtnDefault, {
          // [classes.inverted]: inverted 
          [classes.ctaBtnDefaultInverted]: inverted
        })}
        style={{ ...btnStyles }}
        href={ctaHref}
        {...rest}
      >
        {ctaText}
      </a>
    )}
  </React.Fragment>
);

export default withStyles(styles, { withTheme: true })(CTAButton);

export const UiButton = withStyles(styles, { withTheme: true })(({
  onClick, children, classes, inverted, btnType, ...rest
}) => (
  <Button
    size="small"
    onClick={onClick}
    className={classNames(classes.uiButton, {
      [classes.uiButtonPrimary]: (btnType === "primary"),
      [classes.uiButtonSecondary]: (btnType === "secondary"),
      [classes.uiButtonWide]: (btnType === "wide"),
      [classes.inverted]: inverted,
      [classes.uiButtonHollow]: (btnType === "hollow"),
    })}
    {...rest}
  >
    {children}
  </Button>
));
