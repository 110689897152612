import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import ensureGtag from '../../helpers/ensureGtag';

import LogoInverted from '../LogoInverted/LogoInverted';
import { UiButton } from '../CTAButton/CTAButton';

const styles = theme => createStyles({
  drawerHead: {
    height: '12vh',
    padding: 20,
    overflow: 'hidden',
    minWidth: 250,
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  drawerLogo: {
    width: 80,
    whiteSpace: 'nowrap',
    margin: 10,
    [theme.breakpoints.up(350)]: {
      width: 110,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
  },
});

const MainMenuDrawerHead = ({
  classes,
  toggleQuoteComp
}) => (
  <Grid item container
    alignItems="center"
    className={classes.drawerHead}
    wrap="nowrap"
  >
    <Grid item>
      <LogoInverted className={classes.drawerLogo} />
    </Grid>
    <Grid item>
      <UiButton inverted style={{ whiteSpace: 'nowrap' }} onClick={(e) => {
         e.preventDefault();
         e.stopPropagation();

        ensureGtag('event', 'quote_open_from_menu', {
          event_category: 'Quote',
          event_callback: toggleQuoteComp
        });
      }}>
        Quick quote
      </UiButton>
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(MainMenuDrawerHead);
