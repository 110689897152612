import React from 'react';
import Autocomplete from '../Autocomplete/Autocomplete';

const Select = (props) => {
  const options = props.options && props.options.map(option => ({
    label: option.name,
    value: option.value,
    isDisabled: option.disabled,
  }));
  return (
    <Autocomplete
      isClearable={false}
      isSearchable={false}
      {...props} 
      options={options}
      value={props.value && options && options.find(option => option.value === props.value)}
      onChange={(selectedOption) => {
        if (!props.onChange) return;
        if (selectedOption) props.onChange(selectedOption.value);
        else props.onChange(null);
      }}
      
    />
  );
};

export default Select;
