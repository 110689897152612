import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';

import withRoot from '../withRoot';

import MainMenu from './MainMenu/MainMenu';
import Footer from './Footer/Footer';
import Drawer from './Drawer/Drawer';

import { defaultTransition } from '../theme';
import '../global.css';

export const drawerWidthSmall = 250;
export const drawerWidthMedium = 300;
export const drawerWidthLarge = 320;

const styles = theme => {
  const trans = defaultTransition(theme);

  return createStyles({
    root: {
      "::selection": {
        backgroundColor: 'red',
      },
    },

    mainContentWrap: {
      position: 'relative',
      marginRight: 0,
      width: '100%',
      ...defaultTransition(theme, {
        easing: 'linear',
      }),
    },
    mainContentWrapShift: {
      left: -drawerWidthSmall,
      ...trans,

      [theme.breakpoints.up(350)]: {
        left: -drawerWidthMedium,
        ...trans,
      },

      [theme.breakpoints.up(400)]: {
        left: -drawerWidthLarge,
        ...trans,
      },

      [theme.breakpoints.up(800)]: {
        left: 0,
        width: `calc(100% - ${drawerWidthLarge}px)`,
        ...trans,
      },
    },
    mainContent: {
      width: '100%',
      paddingTop: '12vh',
      flexGrow: 1,
    },
  });
};

class Layout extends React.Component {
  state = {
    isMenuOpen: false,
  };

  lockScroll = () => {
    if(isMobile()) {
      // set html
      document.body.parentNode.style.overflow = 'hidden';
      document.body.parentNode.style.height = '100%';
      document.body.parentNode.style.margin = 0;
      // set body
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
      document.body.style.margin = 0;
    }
  };

  unlockScroll = () => {
    // set html
    document.body.parentNode.style.overflow = 'auto';
    document.body.style.height = '';
    // set body
    document.body.parentNode.style.overflow = 'auto';
    document.body.style.height = '';
  }

  setMenuState = (value) => {
    this.setState({
      isMenuOpen: value,
    });
  }

  openMenu = () => {
    this.setMenuState(true);
    this.lockScroll();
  };

  closeMenu = () => {
    this.setMenuState(false);
    this.unlockScroll();
  };

  render() {
    const { children, classes, toggleQuoteComp, withFooter = true, footerClass=false } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <Grid
        container
        className={classes.root}
        direction="column"
      >
        <MainMenu
          closeMenu={this.closeMenu}
          openMenu={this.openMenu}
          isMenuOpen={isMenuOpen}
        />
        <Grid
          container
          item
          direction="column"
          className={classNames(classes.mainContentWrap, {
            [classes.mainContentWrapShift]: isMenuOpen,
          })}
        >
          <Grid item container
            id="mainContent"
            direction="column"
            className={classes.mainContent}
          >
            <Grid
              component="main"
              style={{ width: '100%' }}
              onClick={this.closeMenu}
            >
              {children}
            </Grid>
            {withFooter && <Footer footerClass={footerClass} />}
          </Grid>
        </Grid>
        <Drawer
          toggleQuoteComp={toggleQuoteComp}
          isMenuOpen={isMenuOpen}
          closeMenu={this.closeMenu}
        />
      </Grid>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRoot(withStyles(styles, { withTheme: true })(Layout));
