import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { enlargePercent } from '../../theme';

import ensureGtag from '../../helpers/ensureGtag';

import { UiButton } from '../CTAButton/CTAButton';
import ResultSnackbar from '../ResultSnackbar/ResultSnackbar';
import CloseButton from './CloseButton';
import Input from './Input';

const totalColor = '#1BB25F';

const turfs = {
  tt: 'TifTuf',
  sw: 'Sir Walter DNA Certified',
  sg: 'Sir Grange',
  nc: 'Nullarbor Couch',
};

const prices = {
  nc:	9.90,
  sw:	16.60,
  tt: 16.60,
  sg: 23.20,
};

const delivery = 165;
const fixedPrice = 440;
const priceMultiplier = 2.50;
const areaLimit = 176;

const styles = theme =>
  createStyles({
    root: {},
    summaryWrap: {
      textAlign: 'center',
      backgroundColor: '#e9e9e9',
      padding: '15px 40px',
      height: '45vh',

      [theme.breakpoints.up(400)]: {
        padding: '30px 50px',
      },
    },
    header: {
      fontSize: '1.5rem',
      lineHeight: 1.7,
      fontWeight: '600',

      [theme.breakpoints.up(400)]: {
        fontSize: `${enlargePercent(1.7)}rem`,
        lineHeight: 2.2,
      },
    },
    headerSummary: {
      fontSize: '1.3rem',
      lineHeight: 1.5,
      fontWeight: 500,

      [theme.breakpoints.up(400)]: {
        fontSize: `${enlargePercent(1.3)}rem`,
        lineHeight: 1.9,
      },
    },
    subHeader: {
      padding: '0 0 15px',
      fontSize: '1.1rem',
      lineHeight: 1.3,
      fontWeight: 300,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      marginBottom: 15,
      [theme.breakpoints.up(400)]: {
        fontSize: `${enlargePercent(1.3)}rem`,
      },
    },
    total: {
      color: totalColor,
      fontSize: '1.55rem',
      fontWeight: '600',
      marginBottom: 15,

      [theme.breakpoints.up(400)]: {
        fontSize: `${enlargePercent(1.75)}rem`,
      },
    },
    detailWrap: {
      textAlign: 'center',
      backgroundColor: '#fff',
      padding: '10px 40px',
      height: '55vh',

      [theme.breakpoints.up(350)]: {
        padding: '20px 50px',
      },
    },
    detailTable: {
      width: '100%',
      textAlign: 'left',
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.7,
      margin: '15px 0',
      borderCollapse: 'collapse',
      [theme.breakpoints.up(400)]: {
        fontSize: `${enlargePercent(1)}rem`,
      },

      '& tr td': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '& tr:last-child td': {
        borderBottom: 0,
      },
    },
    amount: {
      fontWeight: 600,
      textAlign: 'right',
    },
    totalCell: {
      marginBottom: 25,
      color: totalColor,
    },
    resultContentWrap: {
      maxWidth: 600,
      height: '100%',
      alignSelf: 'center',
      maxHeight: 400,
      [theme.breakpoints.up(800)]: {
        paddingTop: 40,
      }
    },
    emailQuoteInputBox: {
      width: '100%',
      padding: '0px 20px',
      marginBottom: 15,
    }
  });

const getTotal = ({
  area,
  turf,
  suburb,
  pro,
}) => {
  let total = 0;

  // turf price
  total += prices[turf] * area;

  // if pro installation
  if (pro) {
    if (area < areaLimit) {
      total += fixedPrice;
    } else {
      total += (priceMultiplier * area);
    }
  }

  total += +delivery;

  return total.toFixed(2);
}

const EmailQuoteForm = ({ getInputProps, onSubmit, isLoadingSubmit }) => (
  <form onSubmit={onSubmit}>
    <Input placeholder='Your name' {...getInputProps('fullName')} />
    <Input placeholder='Your email' {...getInputProps('email')} />
    <UiButton type='submit' btnType='wide' disabled={isLoadingSubmit}>
      Send
    </UiButton>
  </form>
);

const Result = ({
  classes,
  closeFn,
  result,
  onChange,
  onClickEmailQuote,
  isEmailingQuote,
  emailFormValues,
  onSubmitEmailQuote,
  isLoadingSubmitEmailQuote,
  snack,
  onCloseSnack
}) => (
  <Grid item container>
    <CloseButton iconProps={{ color: "primary" }} closeFn={() => {
      const action = isEmailingQuote ? `email_form`: `result`;

      ensureGtag('event', `quote_closed_on_${action}_step`, {
        event_category: 'Quote',
        event_callback: closeFn,
      });
    }} />
    <Grid
      item
      container
      className={classes.summaryWrap}
      justify="center"
      style={isEmailingQuote ? { height: '100vh' } : {}}
    >
      <Grid item container
        className={classes.resultContentWrap}
        direction="column"
        alignContent="center"
        justify="center"
        wrap="nowrap"
      >
        <Typography
          color="primary"
          variant="h3"
          component="h3"
          className={classes.header}
        >
          Your quote
        </Typography>
        <Typography
          color="primary"
          variant="h5"
          component="h5"
          className={classes.subHeader}
        >
          {result.pro && `including turf, delivery and install is:`}
        </Typography>
        <Typography
          color="primary"
          variant="h3"
          component="h3"
          className={classes.total}
        >
          ${getTotal(result)}
        </Typography>
        {!isEmailingQuote && (
          <UiButton btnType='wide' onClick={onClickEmailQuote}>
            Email quote
          </UiButton>
        )}
        {isEmailingQuote && (
          <>
            <EmailQuoteForm
              getInputProps={field => ({
                value: emailFormValues[field],
                onChange: (event) => {
                  const { value } = event.target;
                  onChange({ [field]: value })
                },
                required: true,
                disabled: isLoadingSubmitEmailQuote,
                classes: { formControl: classes.emailQuoteInputBox }
              })}
              onSubmit={onSubmitEmailQuote}
              isLoadingSubmit={isLoadingSubmitEmailQuote}
            />
          </>
        )}
        {snack && <ResultSnackbar {...snack} onClose={onCloseSnack} />}
      </Grid>
    </Grid>
    <Grid
      item
      container
      className={classes.detailWrap}
      justify="center"
    >
      <Grid item container
        className={classes.resultContentWrap}
        direction="column"
        alignContent="center"
        justify="center"
      >
        <Typography
          color="primary"
          variant="h5"
          component="h5"
          className={classes.headerSummary}
        >
          Detailed summary
        </Typography>
        <table className={classes.detailTable}>
          <tbody>
            <tr>
              <td colSpan="2">{result.area}m<sup>2</sup> x {turfs[result.turf]}</td>
            </tr>
            <tr>
              <td>@ ${prices[result.turf]}/m<sup>2</sup></td>
              <td className={classes.amount}>${(prices[result.turf] * result.area).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Delivery</td>
              <td className={classes.amount}>${delivery}</td>
            </tr>
            {result.pro && (
              <tr>
                <td>Installation</td>
                <td className={classes.amount}>${(result.area < areaLimit) ? fixedPrice : (priceMultiplier * result.area).toFixed(2)}</td>
              </tr>
            )}
            <tr>
              <td>Total</td>
              <td className={classes.amount}>
                <span className={classes.totalCell}>${getTotal(result)}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(
  class WrappedQuote extends React.Component {
    componentDidMount() {
      ensureGtag('event', 'quote_result_seen', {
        event_category: 'Quote',
      });
    }

    render () {
       return <Result {...this.props} />;
    }
  }
);
