import React from 'react';
import TransitionalQuote from './Quote/TransitionalQuote';
import { turfOptions } from './Quote/Quote';
import Layout from './layout';

const validTurfs = turfOptions.map(({ value }) => value);

export const withQuoteWizard = (WrappedComponent, withFooter = true, footerClass=false) => {
  class QuoteHOC extends React.Component {
    state = {
      isQuoteWizardOpen: false,
      defaultTurf: null,
    };

    toggleQuoteWizard = turf => {
      if (validTurfs.includes(turf)) {
        this.setState({ defaultTurf: turf });
      } else {
        this.setState({ defaultTurf: null });
      }
      this.setState(prevState => ({
        isQuoteWizardOpen: !prevState.isQuoteWizardOpen,
      }));
    };

    render() {
      return (
        <Layout
          toggleQuoteComp={this.toggleQuoteWizard}
          withFooter={withFooter}
          footerClass={footerClass}        
        >
          <WrappedComponent
            toggleQuoteComp={this.toggleQuoteWizard}
            {...this.props}
          />
          <TransitionalQuote
            isQuoteWizardOpen={this.state.isQuoteWizardOpen}
            toggleQuoteWizard={this.toggleQuoteWizard}
            defaultTurf={this.state.defaultTurf}
          />
        </Layout>
      );
    }
  }

  return QuoteHOC;
};
