import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';

import {
  ListItem,
  Collapse,
  Grid,
  List,
  Typography,
} from '@material-ui/core';
import MainMenuItem from './MainMenuItem';

import { defaultTransition } from '../../theme';

const styles = theme => {
  const transitionDefault = defaultTransition(theme);

  const getLinkStyling = (theme) => ({
    textDecoration: 'none',
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 350,
    lineHeight: '1.40',
    fontSize: '1rem',
    verticalAlign: 'middle',
    [theme.breakpoints.up(400)]: {
      fontSize: `${theme.typography.enlargePercent(1)}rem`,
    }
  });

  return createStyles({
    root: {
      height: '76vh',
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
    menuItem: {
      padding: '15px 20px',
      borderBottom: `1px solid rgba(255, 255, 255, 0.1)`,
      cursor: 'pointer',
      marginLeft: 0,
      ...transitionDefault,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    expandableLink: {
      ...getLinkStyling(theme),
    },
    expandableLinkIcon: {
      color: theme.palette.common.white,
      alignSelf: 'flex-end',
    },
    list: {
      paddingLeft: 10,
    }
  })};

const Submenu = Object.freeze({
  // Services: 'Services',
  Lawns: 'Lawns',
  Construction: 'Construction'
  
});

class MainMenuList extends Component {
  state = {
    // isServicesOpen: false,
    isLawnsOpen: false,
    isConstructionOpen: false,
    
  };

  toggleSubMenu = (submenu) => (isOpen) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      [`is${submenu}Open`]: !isOpen,
    });
  };

  render() {
    const { classes } = this.props;
     return (
      <Grid item className={classes.root}>
        <List className={classes.list} body-scroll-lock-ignore="true">
          {[
            { linkName: 'Home', href: '/' },
            { linkName: 'About', href: '/about-us' },
            
            {
              isList: true,
              listName: 'Lawns',
              listItems: [
                {
                  linkName: 'Overview',
                  href: '/turf-varieties',
                },
                {
                  linkName: 'TifTuf',
                  href: '/turf-tiftuf',
                },
                {
                  linkName: 'Sir Walter DNA Certified',
                  href: '/turf-sir-walter-dna-certified',
                },
                {
                  linkName: 'Sir Grange',
                  href: '/turf-sir-grange',
                },
                {
                  linkName: 'Nullarbor Couch',
                  href: '/turf-nullarbor-couch',
                },
              ]
            },
            { linkName: 'Trade', href: '/trade' },
            { linkName: 'Guides', href: '/guides' },

            {
              isList: true,
              listName: 'Construction',
              listItems: [
                {
                  linkName: 'Overview',
                  href: '/construction',
                },
                {
                  linkName: 'Our Projects',
                  href: '/our-projects',
                },
                {
                  linkName: 'Our People',
                  href: '/our-people',
                },
                {
                  linkName: 'Our Services',
                  href: '/our-services',
                }
              ],
            },

            { linkName:'Renovation & Maintenance', href: '/renovation' },
            { linkName: 'Golf Courses', href: '/golf-courses', },
            { linkName: 'Public Spaces', href: '/public-spaces', },
            
            { linkName: 'News/Tips', href: '/news-and-tips' },
            { linkName: 'Testimonials', href: '/testimonials' },
            { linkName: 'Contact', href: '/contact' },
          ].map((item) => {
            if (item.isList) {
              const submenu = Submenu[item.listName];
              let isOpen = this.state[`is${submenu}Open`];
              
                return (
                <React.Fragment key={submenu}>
                  <ListItem component="li" className={classes.menuItem} button onClick={this.toggleSubMenu(submenu)(isOpen)}>
                    <Typography className={classes.expandableLink}>
                      {submenu}
                    </Typography>
                    <Grid className={classes.expandableLinkIcon}>
                      {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </Grid>
                  </ListItem>
                  <Collapse component="li" in={isOpen} style={{ backgroundColor: '#0D0E27' }} timeout="auto" unmountOnExit>
                    <List component="ul" disablePadding>
                      {item.listItems.map(({ linkName, href}) => (
                        <MainMenuItem key={linkName} linkName={linkName} href={href} />
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }

            return <MainMenuItem key={item.linkName} linkName={item.linkName} href={item.href} />;
          })}
        </List>
      </Grid>
    );
  }
};

export default withStyles(styles, { withTheme: true })(MainMenuList);
