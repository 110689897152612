import {
  createMuiTheme,
  createGenerateClassName,
} from '@material-ui/core/styles';
import { SheetsRegistry } from 'jss';

import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const primaryColor = '#181A38';

export const pxToRem = (value) => (`${value / 16}rem`);

export const up = (value) => (`@media (minWidth: ${value} px)`);

export const enlargePercent = (base, percent = 10) => (base + (percent/100*base));

export const CenterStyle = {
  width: '100%',
  maxWidth: 1440,
  margin: '0 auto',

}

export const CenterStyleMain = {
  width: '100%',
  maxWidth: 1315,
  margin: '0 auto',

}

export const CenterStyleNarrow = {
  width: '100%',
  maxWidth: 1190,
  margin: '0 auto',

}

export const defaultTransition = (theme, options = {}) => {
  const { attrs, ...rest } = {...{
    attrs: ['all'],
    easing: 'ease-in-out',
    duration: 300,
    delay: 0,
  }, ...options };

  const trans = theme.transitions.create(attrs, {
    ...rest
  });

  return {
    [`-webkit-transition`]: trans,
    [`-moz-transition`]: trans,
    [`-o-transition`]: trans,
    transition: trans,
  };
}

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  breakpoints,
  typography: {
    enlargePercent,
    useNextVariants: true,
    fontFamily: [
      // 'Halyard Display',
      'halyard-display, sans-serif',
      '-apple-system',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    fontFamilySocial: [
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#ffffff',
    },
    green: {
      main: '#1BB25F'
    },
    text: {
      primary: primaryColor,
    },
    lightPink: {
      main: '#ECBAAB',
    },
    grey: {
      main: '#E9E9E9'
    },
  },
  overrides: {
    MuiGrid: {
    },
    MuiDrawer: {
      paperAnchorRight: {
      },
      paperAnchorDockedRight: {
        borderLeft: `1px solid ${primaryColor}`,
      },
    },
    MuiTypography: {
      h1: {
        fontSize: pxToRem(40),
        fontWeight: '600',
        [breakpoints.up(400)]: {
          fontSize: pxToRem(45),
        }
      },
      h2: {
        fontSize: pxToRem(30),
        fontWeight: '600',
        textAlign: 'center',
        [breakpoints.up(400)]: {
          fontSize: pxToRem(45),
        }
      },
      h4: {
        fontWeight: 400,
        lineHeight: 1.3,
        fontSize: pxToRem(36),
        [breakpoints.up(400)]: {
          fontSize: pxToRem(45),
        },
      },
      h5: { // articles quotes styling
        fontWeight: 600,
        lineHeight: 1.3,
        fontSize: pxToRem(20),
        [breakpoints.up(1100)]: {
          fontSize: pxToRem(26),
        },
      },
      h6: { // articles quotes styling
        fontWeight: 400,
        lineHeight: 1.3,
        fontSize: pxToRem(20),
        [breakpoints.up(1100)]: {
          fontSize: pxToRem(26),
        },
      },
      body1: {
        fontSize: pxToRem(18),
        lineHeight: 1.3,
        fontWeight: 300,
        [breakpoints.up(400)]: {
          fontSize: pxToRem(20),
        }
      },
      body2: { // articles quotes styling
        fontWeight: 600,
        lineHeight: 1.3,
        fontSize: pxToRem(26),
        [breakpoints.up('md')]: {
          fontSize: pxToRem(30),
        },
      },

    },
    MuiMobileStepper: {
      root: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
      },
      dots: {
        backgroundColor: 'transparent',
      },
      dot: {
        margin: 10,
        height: 20,
        width: 20,
        borderRadius: 10,
        backgroundColor: primaryColor,
      },
      dotActive: {
        backgroundColor: '#1BB25F',
      },
    },
  },
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName({
      productionPrefix: 'twt',
    }),
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
