export const General = {
  notProvided: 'notProvided.jpg',
};

export const LogoImage = {
  logo: 'logo.png',
};

export const HomePage = {
  hero: 'photos/Home/Feature/image.jpg',
  slide: {
    image1: 'photos/Home/Sliders/Fresh/image.jpg',
    image2: 'photos/Home/Sliders/Service/image.jpg',
    image3: 'photos/Home/Sliders/Quality (2 options)/image1.jpg',
  },
  testimonials: {
    main: 'photos/Home/testimonial/Pam&Mark.jpg',
  },
};

export const AboutPage = {
  section1: 'photos/About/Team/image1.jpg',
  section2: 'photos/About/Experience/image.jpg',
  section3: 'photos/About/Premium fleet/image.jpg',
  section4: 'photos/About/Industry leading technology/image.jpg',
  section5: 'photos/About/Irrigators/image1.jpg',
  section6: 'photos/About/LSA Accredited Grower/image.jpg',
};

export const TurfVariatiesPage = {
  TifTuf: 'photos/Varieties Home/TifTuf/image.jpg',
  SirGrange: 'photos/Varieties Home/Sir Grange/image.jpg',
  Nullarbor: 'photos/Varieties Home/Nullarbor Couch/image.jpg',
  SirWalter: 'photos/Varieties Home/Sir Walter DNA Certified/image.jpg',
  waterMark: 'photos/Varieties Home/SAWM_LSA_Landscape.png',
  clients: {
    markPam: 'photos/Home/testimonial/Pam&Mark.jpg',
  },
};

export const TifTufPage = {
  hero: 'photos/Varieties Feature/TifTuf/Header/image.jpg',
  happyCustomer: 'photos/Varieties Home/Testimonials/TT/TifTuf.jpg',
  waterMark: 'photos/Varieties Home/SAWM_LSA_Landscape.png',
  slides: {
    slide1: 'photos/Varieties Feature/TifTuf/Point 1 – soft/image.JPEG',
    slide2: 'photos/Varieties Feature/TifTuf/Point 2 – thick/image.jpg',
    slide3: 'photos/Varieties Feature/TifTuf/Point 3 – water/image.jpg',
  },
  closeUp: 'photos/Varieties Feature/TifTuf/Up close feature/image.jpg',
};

export const SirWalterPage = {
  hero: 'photos/Varieties Feature/Sir Walter DNA Certified/Header/image.jpg',
  happyCustomer: 'photos/Varieties Home/Testimonials/SW/Sir Walter.jpg',
  waterMark: 'photos/Varieties Home/SAWM_LSA_Landscape.png',
  slides: {
    slide1:
      'photos/Varieties Feature/Sir Walter DNA Certified/Point 1 – lush/image.JPG',
    slide2:
      'photos/Varieties Feature/Sir Walter DNA Certified/Point 2 – beautiful/image.JPG',
    slide3:
      'photos/Varieties Feature/Sir Walter DNA Certified/Point 3 – recover/image.jpg',
  },
  closeUp:
    'photos/Varieties Feature/Sir Walter DNA Certified/Up close feature/image.jpg',
};

export const SirGrangePage = {
  hero: 'photos/Varieties Feature/Sir Grange/Header/image.jpg',
  happyCustomer: 'photos/Varieties Home/Testimonials/SG/Sir Grange.jpg',
  waterMark: 'photos/Varieties Home/SAWM_LSA_Landscape.png',
  slides: {
    slide1:
      'photos/Varieties Feature/Sir Grange/Point 1 – finest leaf/image.jpg',
    slide2: 'photos/Varieties Feature/Sir Grange/Point 2 – low mow/image.png',
    slide3: 'photos/Varieties Feature/Sir Grange/Point 3 – practical/image.jpg',
  },
  closeUp: 'photos/Varieties Feature/Sir Grange/Up close feature/image.jpg',
};

export const NullarborPage = {
  hero: 'photos/Varieties Feature/Nullarbor Couch/Header/image.jpg',
  happyCustomer: 'photos/Varieties Home/Testimonials/NC/Nullarbor Couch.jpg',
  waterMark: 'photos/Varieties Home/SAWM_LSA_Landscape.png',
  slides: {
    slide1:
      'photos/Varieties Feature/Nullarbor Couch/Point 1 – fine and hardy/image.jpg',
    slide2:
      'photos/Varieties Feature/Nullarbor Couch/Point 2 – self repair/image.jpg',
    slide3:
      'photos/Varieties Feature/Nullarbor Couch/Point 3 – sunny conditions/image.jpg',
  },
  closeUp:
    'photos/Varieties Feature/Nullarbor Couch/Up close feature/image.jpg',
};

export const LandscapersTradePage = {
  hero: 'photos/Landscapers & Trade/Header/image.jpg',
  testimonialHero: 'photos/Landscapers & Trade/Testimonial header/image.jpg',
  happyCustomer: 'photos/Landscapers & Trade/Testimonial/image.jpg',
};

export const GuidesPage = {
  hero: 'photos/Guides/Header/image.jpg',
  article1: 'photos/Guides/Measuring your site/Header/image.jpg',
  article2: 'photos/Guides/New lawns/Header/image.jpg',
  article3: 'photos/Guides/Replacement lawns/Header/image.jpg',
};

export const SportsRenovationPage = {
  hero: 'photos/Renovation Main/Header/image.jpg',
  aeration: 'photos/Renovation Main/Aerating/image1.jpg',
  drainage: 'photos/Renovation Main/Drainage/image.jpg',
  mowing: 'photos/Renovation Main/Mowing/image.jpg',
  turfNutrition: 'photos/Renovation Main/Turf nutrition/image.jpg',
  turfPatching: 'photos/Renovation Main/Turf patching/SportsfieldTurfPatch.jpg',
  sweepingScarifying: 'photos/Renovation Main/Sweeping & scarifying/image.jpg',
  topDressing: 'photos/Renovation Main/Top dressing/image.jpg',
  topMaking: 'photos/Renovation Main/Top making/image.jpg',
};

export const SportsConstructionPage = {
  hero: 'photos/Construction Main/Header/image.jpg',
  article1: 'photos/Construction Main/Redcliffe Dolphins/image.jpg',
  article2: 'photos/Construction Main/Centenary Lakes/image.jpg',
};

export const GolfCoursesPage = {
  hero: 'photos/Golf Courses Main/Header/image.jpg',
  article1: 'photos/Golf Courses Main/RACV Royal Pines/image.jpg',
  article2: 'photos/Golf Courses Main/Maroochy River Golf Club/image.jpg',
};

export const PublicSpacesPage = {
  hero: `photos/Public Spaces Main/Header/image.jpg`,
  gabba: `photos/Public Spaces Main/Gabba/image.jpg`,
  southbank: `photos/Public Spaces Main/Southbank/image.jpg`,
};

export const NewsTipsPage = {
  freshBest: `photos/News & Tips Main & Features/Fresh is best/image.jpg`,
  rainNotEnough: `photos/News & Tips Main & Features/Rain isn't always enough/image.jpg`,
  fertiliseTiming: `photos/News & Tips Main & Features/When should I fertilise/image.jpg`,
};

export const ContactPage = {
  callUs: `photos/Contact/Call us/image.jpg`,
  visitUs: `photos/Contact/Visit us/image.jpg`,
  emailUs: `photos/Contact/Email us/image.jpg`,
};

export const CampaignPage = {
  hero: `campaigns/live-your-best-life/young-and-cheerful-woman.jpg`,
  feelGoodSoccer: `campaigns/live-your-best-life/feel-good-soccer.jpg`,
  dog: `campaigns/live-your-best-life/dog.png`,
  soccer: `campaigns/live-your-best-life/soccer.png`,
  cricket: `campaigns/live-your-best-life/cricket.png`,
  shoes: `campaigns/live-your-best-life/shoes.png`,
  kettlebell: `campaigns/live-your-best-life/kettlebell.png`,
  skipping: `campaigns/live-your-best-life/skipping.png`,
  feelGoodPlanks: `campaigns/live-your-best-life/feel-good-planks.jpg`,
  feelGoodAerial: `campaigns/live-your-best-life/feel-good-aerial.jpg`,
  feelGoodBubbles: `campaigns/live-your-best-life/feel-good-bubbles.jpg`,
  champagne: `campaigns/live-your-best-life/champagne-glasses.png`,
  flower: `campaigns/live-your-best-life/flower.png`,
  heart: `campaigns/live-your-best-life/heart.png`,
  laugh: `campaigns/live-your-best-life/laugh.png`,
  tongs: `campaigns/live-your-best-life/tongs.png`,
  hero2: `campaigns/live-your-best-life/watering-lawns.jpg`,
};

export const OurPeopleImages = {
  hero: `photos/Our People/Header/IMG_8124.jpg`,
  member2: `photos/Our People/Profile Photos/John Shaw-7.jpg`,
  member5: `photos/Our People/Profile Photos/Jy Cross-59.jpg`,
  member7: `photos/Our People/Profile Photos/Katrina Elsden-41.jpg`,
  member1: `photos/Our People/Profile Photos/Lawrence Stephenson-80.jpg`,
  member6: `photos/Our People/Profile Photos/Scott Heather.jpg`,
  member8: `photos/Our People/Profile Photos/Paul Weldon-69.jpg`,
  member9: `photos/Our People/Profile Photos/Ryan Pancari-27.jpg`,
  member4: `photos/Our People/Profile Photos/Sabra Matthews-109.jpg`,
  member10: `photos/Our People/Profile Photos/Sam Hall.jpg`,
  member3: `photos/Our People/Profile Photos/Trent Hobson-110.jpg`,
};

export const OurProjectsImages = {
  image1: `photos/Our Projects/IMG_5835.jpg`,
  image2: `photos/Our Projects/IMG_6393_.jpg`,
  image3: `photos/Our Projects/IMG_1885_AFTER.jpg`,
  image4: `photos/Our Projects/IMG_5727_HR.jpg`,
  image5: `photos/Our Projects/IMG_9428-2_EDIT.jpg`,
  image6: `photos/Our Projects/Bribie Soccer Complex/Header/header.jpg`,
  image7: `photos/Our Projects/D.M. Henderson Park/Header/header.jpg`,
  image8: `photos/Our Projects/Stanley Day Park/Header/header.jpg`,
  image9: `photos/Our Projects/Giffin Park/Header/header.jpg`,
};

export const SingleProjectsIcons = {
  icon1: `photos/Our Projects/Single Project icons/truck.png`,
  icon2: `photos/Our Projects/Single Project icons/ground.png`,
  icon3: `photos/Our Projects/Single Project icons/grass.png`,
  icon4: `photos/Our Projects/Single Project icons/hand.png`,
  icon5: `photos/Our Projects/Single Project icons/dollar.png`,
};

export const DolphinsStadiumImages = {
  hero: `photos/Our Projects/Dolphins Stadium/Slider_Header/IMG_5835.jpg`,
  feature: `photos/Our Projects/Dolphins Stadium/Feature/IMG_5813.jpg`,
  thumb: `photos/Our Projects/Dolphins Stadium/Thumbnail/IMG_2596.jpg`,
  slide1: `photos/Our Projects/Dolphins Stadium/Transformation/1/IMG_5816.jpg`,
  slide2: `photos/Our Projects/Dolphins Stadium/Transformation/2/Redcliffe Dolphins Before.jpg`,
};

export const MitcheltonParkImages = {
  hero: `photos/Our Projects/Mitchelton Park/Slider_Header/IMG_5727_EDIT.jpg`,
  feature: `photos/Our Projects/Mitchelton Park/Feature/IMG_5737_EDIT.jpg`,
  thumb1: `photos/Our Projects/Mitchelton Park/Thumbnail/Topmaking Process3.jpg`,
  thumb2: `photos/Our Projects/Mitchelton Park/Thumbnail/Rotary Hoe Process1.jpg`,
  thumb3: `photos/Our Projects/Mitchelton Park/Thumbnail/Laser level process1.jpg`,
  slide1: `photos/Our Projects/Mitchelton Park/Transformation/1/IMG_5757.jpg`,
  slide2: `photos/Our Projects/Mitchelton Park/Transformation/2/Mitchelton-Before.jpg`,
};

export const OCallaghanParkImages = {
  hero: `photos/Our Projects/OCallaghan Park/Slider_Header/IMG_6393_.jpg`,
  feature1: `photos/Our Projects/OCallaghan Park/Feature/1/IMG_6442_.jpg`,
  feature2: `photos/Our Projects/OCallaghan Park/Feature/2/IMG_6445_.jpg`,
  thumb1: `photos/Our Projects/OCallaghan Park/Thumbnail/1/Drainage2.jpg`,
  thumb2: `photos/Our Projects/OCallaghan Park/Thumbnail/2/Drainage.jpg`,
  thumb3: `photos/Our Projects/OCallaghan Park/Thumbnail/3/20191219_092500.jpg`,
  slide1: `photos/Our Projects/OCallaghan Park/Transformation/1/O'Callaghan-After.jpg`,
  slide2: `photos/Our Projects/OCallaghan Park/Transformation/2/O'Callaghan-Before.jpg`,
};

export const TaigumPlaceParkImages = {
  hero: `photos/Our Projects/Taigum Place Park/Slider_Header/IMG_1885_AFTER.jpg`,
  feature: `photos/Our Projects/Taigum Place Park/Feature/IMG_5813.jpg`,
  thumb1: `photos/Our Projects/Taigum Place Park/Thumbnail/1/20191202_152124.jpg`,
  thumb2: `photos/Our Projects/Taigum Place Park/Thumbnail/2/IMG_6133.jpg`,
  thumb3: `photos/Our Projects/Taigum Place Park/Thumbnail/3/IMG_6132.jpg`,
  slide1: `photos/Our Projects/Taigum Place Park/Transformation/1/IMG_1885_AFTER.jpg`,
  slide2: `photos/Our Projects/Taigum Place Park/Transformation/2/IMG_6176.jpg`,
};

export const SouthPineSportsImages = {
  slide1: `photos/Our Projects/South Pine Sports Complex/Transformation/SPSC_After.jpg`,
  slide2: `photos/Our Projects/South Pine Sports Complex/Transformation/SPSC_Before.jpg`,
  feature: `photos/Our Projects/South Pine Sports Complex/Feature/IMG_9428-2_EDIT.jpg`,
  thumb1: `photos/Our Projects/South Pine Sports Complex/Thumbnails/Friday_6th.jpg`,
  thumb2: `photos/Our Projects/South Pine Sports Complex/Thumbnails/rsz_Pipe_install.jpg`,
  thumb3: `photos/Our Projects/South Pine Sports Complex/Thumbnails/rsz_Lateral_trenching.jpg`,
};

export const BribieSoccerComplexImages = {
  hero: `photos/Our Projects/Bribie Soccer Complex/Header/header.jpg`,
  feature: `photos/Our Projects/Bribie Soccer Complex/Feature/feature.jpg`,
  thumb: `photos/Our Projects/Bribie Soccer Complex/Thumbnail/thumbnail.jpg`,
  slide1: `photos/Our Projects/Bribie Soccer Complex/Transformation/after.jpg`,
  slide2: `photos/Our Projects/Bribie Soccer Complex/Transformation/before.jpg`,
};

export const DMHendersonParkImages = {
  hero: `photos/Our Projects/D.M. Henderson Park/Header/header.jpg`,
  feature: `photos/Our Projects/D.M. Henderson Park/Feature/feature.jpg`,
  slide1: `photos/Our Projects/D.M. Henderson Park/Transformation/after.jpg`,
  slide2: `photos/Our Projects/D.M. Henderson Park/Transformation/before.jpg`,
};

export const GiffinParkImages = {
  hero: `photos/Our Projects/Giffin Park/Header/header.jpg`,
  feature: `photos/Our Projects/Giffin Park/Feature/feature.jpg`,
  thumb: `photos/Our Projects/Giffin Park/Thumbnail/thumbnail.jpg`,
  slide1: `photos/Our Projects/Giffin Park/Transformation/after.jpg`,
  slide2: `photos/Our Projects/Giffin Park/Transformation/before.jpg`,
};

export const StanleyDayParkImages = {
  hero: `photos/Our Projects/Stanley Day Park/Header/header.jpg`,
  feature: `photos/Our Projects/Stanley Day Park/Feature/feature.jpg`,
  thumb: `photos/Our Projects/Stanley Day Park/Thumbnail/thumbnail.jpg`,
  slide1: `photos/Our Projects/Stanley Day Park/Transformation/after.jpg`,
  slide2: `photos/Our Projects/Stanley Day Park/Transformation/before.jpg`,
};

export const OurServicesImages = {
  hero: `photos/Our Services/Header/IMG_8955.jpg`,
  cardImg1: `photos/Our Services/Card Images/IMG_5835.jpg`,
  cardImg2: `photos/Our Services/Card Images/Drainage.jpg`,
  cardImg3: `photos/Our Services/Card Images/IMG_2317.jpg`,
  cardImg4: `photos/Our Services/Card Images/IMG_0624.jpg`,
  cardImg5: `photos/Our Services/Card Images/IMG_8484.jpg`,
  cardImg6: `photos/Our Services/Card Images/IMG_5737_EDIT.jpg`,
};

export const DrainageServiceImages = {
  hero: `photos/Single Service/Drainage & Irrigation/Header/IMG_7902.jpg`,
  feature1: `photos/Single Service/Drainage & Irrigation/Slider/1/IMG_5032.jpg`,
  feature2: `photos/Single Service/Drainage & Irrigation/Slider/2/IMG_4880.jpg`,
  feature3: `photos/Single Service/Drainage & Irrigation/Slider/3/Lateral Gravel Backfill2.jpg`,
  thumb1: `photos/Single Service/Drainage & Irrigation/Thumbnails/Drainage Plan.png`,
  thumb2: `photos/Single Service/Drainage & Irrigation/Thumbnails/Drainage.jpg`,
  thumb3: `photos/Single Service/Drainage & Irrigation/Thumbnails/IMG_5043.jpg`,
};

export const SportsTurfServiceImages = {
  hero: `photos/Single Service/Sports Turf/Header/IMG_2317.jpg`,
  feature1: `photos/Single Service/Sports Turf/Slider/1/IMG_2596.jpg`,
  feature2: `photos/Single Service/Sports Turf/Slider/2/IMG_2406.jpg`,
  thumb1: `photos/Single Service/Sports Turf/Thumbnail Slider/1/IMG_5812.jpg`,
  thumb2: `photos/Single Service/Sports Turf/Thumbnail Slider/2/IMG_5489_TifTuf_Close.jpg`,
};

export const RenovationServiceImages = {
  hero1: `photos/Single Service/Renovation & Maintenance/Header Slider/1/IMG_2241.jpg`,
  hero2: `photos/Single Service/Renovation & Maintenance/Header Slider/2/IMG_8524.jpg`,
  hero3: `photos/Single Service/Renovation & Maintenance/Header Slider/3/IMG_6791-2.jpg`,
  thumb: `photos/Single Service/Renovation & Maintenance/Thumbnail/Sherwood-FC_Aeration.jpg`,
};

export const SurfacePreparationServiceImages = {
  hero1: `photos/Single Service/Surface Preparation/Header Slider/1/IMG_8484.jpg`,
  hero2: `photos/Single Service/Surface Preparation/Header Slider/2/IMG_4655.jpg`,
  hero3: `photos/Single Service/Surface Preparation/Header Slider/3/IMG_7010.jpg`,
  thumb: `photos/Single Service/Surface Preparation/Thumbnail/IMG_6974.jpg`,
};

export const ProjectConsulationServiceImages = {
  hero: `photos/Single Service/Project Consultation/Header/IMG_2022.jpg`,
  thumb: `photos/Single Service/Project Consultation/Thumbnail/IMG_2406.jpg`,
};

export const ConstructionPageImages = {
  slide1: `photos/Our Projects/Dolphins Stadium/Slider_Header/IMG_5835.jpg`,
  slide2: `photos/Our Projects/Mitchelton Park/Slider_Header/IMG_5727_HR.jpg`,
  slide3: `photos/Our Projects/OCallaghan Park/Slider_Header/IMG_6393_.jpg`,
  slide4: `photos/Our Projects/Taigum Place Park/Slider_Header/IMG_1885_AFTER.jpg`,
  thumb: `photos/Our People/Header/IMG_8124.jpg`,
  icon1: 'photos/Construction Home/Icons/people.png',
  icon2: 'photos/Construction Home/Icons/ground.png',
  icon3: 'photos/Construction Home/Icons/grass.png',
};
