import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import ensureGtag from '../../helpers/ensureGtag';

import submitForm from '../../helpers/submitForm';

import Select from '../Compare/Select';
import Input from './Input';
import CheckboxGroup from './CheckboxGroup';
import Result from './Result';

import CloseButton from './CloseButton';

import AutoComplete from '../Autocomplete/Autocomplete';
import { suburbOptions } from './suburbs';

const options = [
  {
    text: 'professional install',
    value: 'pro',
  },
];

export const turfOptions = [
  {
    name: 'TifTuf',
    value: 'tt',
    disabled: false,
  },
  {
    name: 'Sir Walter DNA Certified',
    value: 'sw',
    disabled: false,
  },
  {
    name: 'Sir Grange',
    value: 'sg',
    disabled: false,
  },
  {
    name: 'Nullarbor Couch',
    value: 'nc',
    disabled: false,
  },
];

const styles = theme =>
  createStyles({
    root: {
      flex: 1,
      backgroundColor: theme.palette.primary.main,
      padding: '50px 30px 20px',
      textAlign: 'center',
      fontSize: '2rem',
      position: 'relative',
      [theme.breakpoints.up(350)]: {
        padding: '100px 30px 50px',
        fontSize: `${theme.typography.enlargePercent(2)}rem`,
      },
    },
    header: {
      color: '#fff',
      marginBottom: 20,
      fontWeight: 600,
      fontSize: '2.5rem',

      [theme.breakpoints.up(400)]: {
        fontSize: `${theme.typography.enlargePercent(2.5)}rem`,
      },
    },
    helptext: {
      color: '#fff',
      padding: 10,
      fontSize: '1.2rem',
      lineHeight: 1.5,
      [theme.breakpoints.up(400)]: {
        fontSize: `${theme.typography.enlargePercent(1.2)}rem`,
      },
    },
    ctaButton: {
      display: 'inline-block',
      padding: 10,
      margin: 10,
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '1.2rem',
      lineHeight: 1.5,
      borderRadius: 10,
      backgroundColor: '#1BB25F',
      // border: 0,
      border: '1px solid #1BB25F', //
      transition: '0.4s linear', //
      width: '100%',
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'white', //
        color: '#1BB25F', //
      },
      [theme.breakpoints.up(400)]: {
        padding: '10px 25px',
        fontSize: `${theme.typography.enlargePercent(1.2)}rem`,
      },
    },
    suburbs: {},
    resultContentWrap: {
      maxWidth: 500,
      height: '100%',
      alignSelf: 'center',
      maxHeight: 800,
      [theme.breakpoints.up(700)]: {
        paddingTop: 40,
      },
    },
  });

class Quote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quoted: false,
      area: '',
      turf: props.defaultTurf || '',
      suburb: '',
      pro: false,
      fullName: '',
      email: '',
      isEmailingQuote: false,
      isLoadingSubmitEmailQuote: false,
      snack: null,
    };
  }

  componentDidMount() {
    ensureGtag('event', 'quote_opened', {
      event_category: 'Quote',
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultTurf !== prevProps.defaultTurf) {
      this.setState({ turf: this.props.defaultTurf || '' });
    }
  }

  calcuateQuote = () => {
    this.setState({ quoted: true });
  };

  handleChange = change => {
    this.setState(change);
  };

  handleClickEmailQuote = () => {
    ensureGtag('event', 'email_quote_opened', {
      event_category: 'Quote',
      event_callback: () => this.setState({ isEmailingQuote: true }),
    });
  };

  handleSubmitEmailQuote = event => {
    event.preventDefault();
    this.setState({ isLoadingSubmitEmailQuote: true });

    const { area, turf, suburb, pro, fullName, email } = this.state;
    submitForm('Email quote request', {
      customBehavior: 'email-quote-v1',
      area,
      turf,
      suburb,
      pro,
      fullName,
      email,
    })
      .then(() => {
        this.setState({
          snack: {
            variant: 'success',
            message: 'Successfully sent your quote.',
          },
          fullName: '',
          email: '',
          isEmailingQuote: false,
        });

        ensureGtag('event', 'email_quote_sent_success', {
          event_category: 'Quote',
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          snack: { variant: 'error', message: 'Error sending quote.' },
        });

        ensureGtag('event', 'email_quote_sent_fail', {
          event_category: 'Quote',
          value: `Fail: ${error.message && error.message}`,
        });
      })
      .then(() => this.setState({ isLoadingSubmitEmailQuote: false }));
  };

  handleCloseSnack = () => {
    this.setState({ snack: null });
  };

  render() {
    const { classes, closeFn } = this.props;
    const { quoted, fullName, email } = this.state;

    if (quoted)
      return (
        <Result
          result={{ ...this.state }}
          closeFn={closeFn}
          onChange={this.handleChange}
          onClickEmailQuote={this.handleClickEmailQuote}
          isEmailingQuote={this.state.isEmailingQuote}
          emailFormValues={{ fullName, email }}
          onSubmitEmailQuote={this.handleSubmitEmailQuote}
          isLoadingSubmitEmailQuote={this.state.isLoadingSubmitEmailQuote}
          snack={this.state.snack}
          onCloseSnack={this.handleCloseSnack}
        />
      );

    const optionsWithState = [
      {
        ...options[0],
        checked: this.state.pro,
      },
    ];

    return (
      <Grid
        item
        container
        direction="column"
        alignContent="center"
        className={classes.root}
        justify="space-between"
      >
        <CloseButton
          closeFn={() => {
            ensureGtag('event', 'quote_closed_init_form_step', {
              event_category: 'Quote',
              event_callback: closeFn,
            });
          }}
        />
        <Grid
          item
          container
          direction="column"
          className={classes.resultContentWrap}
        >
          <Grid item container direction="column" alignContent="center">
            <Typography variant="h3" component="h3" className={classes.header}>
              Quick quote
            </Typography>
            <Grid item container>
              <Input
                name="square"
                min="2"
                type="number"
                value={this.state.area}
                onChange={event =>
                  this.handleChange({ area: event.target.value })
                }
              />
              <Typography
                variant="h5"
                component="h5"
                className={classes.helptext}
              >
                square meters of
              </Typography>
              <Grid item container>
                <Select
                  placeholder="Select Lawn"
                  value={this.state.turf}
                  onChange={turf => this.handleChange({ turf })}
                  options={turfOptions}
                />
              </Grid>
              <Typography
                variant="h5"
                component="h5"
                className={classes.helptext}
              >
                delivered to:
              </Typography>
              <Grid item container>
                <AutoComplete
                  placeholder="Search suburb"
                  options={suburbOptions}
                  onChange={suburb => {
                    this.handleChange({ suburb });
                  }}
                  className={classes.suburbs}
                  value={this.state.suburb}
                />
              </Grid>
              <CheckboxGroup
                options={optionsWithState}
                onChange={data => {
                  this.handleChange(data);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container justify="center">
            <button
              type="button"
              className={classes.ctaButton}
              onClick={this.calcuateQuote}
            >
              Calculate
            </button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Quote);
