import { get, isFunction } from 'lodash';

// in case gtag script doesn't load
export const createFunctionWithTimeout = (callback, opt_timeout) => {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}

const logger = (...args) => {
  if (console && console.log) {
    console.log(...args);
  }
}

export const getPage = () => {
  const win = window || {};
  return get(win, 'location.pathname') || get(win, 'location');
}

export default (command, action, params) => {
  const win = window || {};
  if (win.gtag) {
    let eventParams = {
      ...params,
      event_label: getPage(),
    };

    if (params && isFunction(params.event_callback)) {
      eventParams = {
        ...eventParams,
        event_callback: createFunctionWithTimeout(params.event_callback),
      };
    }

    logger('gtag GA logged:', command, action, eventParams);
    win.gtag(command, action, eventParams);
  } else {
    logger('window.gtag missing:', command, action, params);
  }
}
